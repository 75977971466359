import { Button } from 'reactstrap'
import styled from 'styled-components'

export const ChatHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 4px;
  height: 57px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #caccce;
  border-right: 1px solid #caccce;
  border-left: 1px solid #caccce;
`

export const ContactActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const IconRight = styled.i`
  color: #caccce;
  padding: 0px 6px;
  font-size: 16px;
`
export const IconLeft = styled.i`
  color: #caccce;
  padding: 10px 4px;
  font-size: 12px;
`

export const AvatarIcon = styled.i`
  font-size: 47px;
  color: #bdbfbf;
`

export const CurrentContactName = styled.span`
  font-weight: 600;
  font-size: 16px;
`

export const WhatsAppIcon = styled.i`
  font-size: 24px;
  color: #00d757;
`
export const EndButton = styled(Button)`
  background: ${props => props.theme.colors.primary};

  :hover {
    background: ${props => props.theme.colors.primary};
  }
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`
