export const SET_AGENT_STATE = 'SET_AGENT_STATE'
export const CLEAR_AGENT_STATE = 'CLEAR_AGENT_STATE'

export const setAgentState = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_AGENT_STATE,
  })
}

export const clearAgentState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_AGENT_STATE,
  })
}
