import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import { fileToBase64 } from '~/functions/File'

export const getResources = async (
  token,
  setImage,
  setDdiOptions,
  setLoading,
) => {
  const [image, ddi] = await Promise.allSettled([
    apiRequest('get', `agent/token/${token}`, {}, setLoading),
    apiRequest('options', 'ddi', {}, setLoading),
  ])

  setImage(image?.value?.data?.image)
  setDdiOptions(ddi?.value?.data)
}

const verifyConditions = data => {
  if (
    data.confirmTermOfService == undefined ||
    data.confirmTermOfService == false
  ) {
    return false
  } else {
    return true
  }
}

export const onSubmit = async (data, token, setLoading) => {
  if (verifyConditions(data)) {
    const body = {
      ...data,
      token: token,
      avatar: await fileToBase64(data.avatar),
    }

    const response = await apiRequest(
      'post',
      'customer',
      body,
      setLoading,
      'Cadastro realizado com sucesso!',
    )

    if (response.error == false)
      setTimeout(() => window.location.reload(), 1000)
  } else {
    toast.info(
      'Para processeguir, é necessário estar de acordo com os termos e condições.',
    )
  }
}
