import { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingContainer from '~/components/LoadingContainer'
import { GetAxios } from '~/services/api'
import { signOut } from '~/store/root/slices/auth'
import { clearContactState } from '~/store/actions/contactAction'
import { clearConversationsState } from '~/store/actions/conversationAction'
import { clearCurrentConversationState } from '~/store/actions/currentConversationAction'
import { clearMessageState } from '~/store/actions/messageAction'
import { clearServiceState } from '~/store/actions/serviceAction'
import { clearCustomerState } from '~/store/actions/customerAction'
import { clearAgentState } from '~/store/actions/agentAction'

const AuthContext = createContext({})

const AuthContextWrapper = ({ children }) => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { axiosAuth } = GetAxios()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getSource() {
      try {
        await axiosAuth.get('/authagent')

        setLoading(false)
      } catch (error) {
        clearContactState(dispatch)
        clearConversationsState(dispatch)
        clearCurrentConversationState(dispatch)
        clearMessageState(dispatch)
        clearServiceState(dispatch)
        clearCustomerState(dispatch)
        clearAgentState(dispatch)

        dispatch(signOut())

        return setLoading(false)
      }
    }

    getSource()

    return () => {
      setLoading(true)
    }
  }, [])

  return (
    <AuthContext.Provider value={{ loading }}>
      {(!auth || auth.loading || loading) && <LoadingContainer />}

      {auth && !auth.loading && !loading && children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthContextWrapper }
