export const SET_MESSAGE = 'SET_MESSAGE'
export const ADD_MEDIA = 'ADD_MEDIA'
export const CLEAR_ALL_MESSAGE = 'CLEAR_ALL_MESSAGE'
export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE'
export const CLEAR_MESSAGE_STATE = 'CLEAR_MESSAGE_STATE'

export const setAllMessages = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_MESSAGE,
  })
}

export const setLastMessage = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_LAST_MESSAGE,
  })
}

export const addMedia = (id, data, dispatch) => {
  dispatch({
    id: id,
    data: data,
    type: ADD_MEDIA,
  })
}

export const clearAllMessages = dispatch => {
  dispatch({
    type: CLEAR_ALL_MESSAGE,
  })
}

export const clearMessageState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_MESSAGE_STATE,
  })
}
