import { onSubmit } from './functions'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { inputs } from './inputs'
import { Container } from './styles'

export default function Form({ setLoading, navigate, loading }) {
  return (
    <Panel>
      <PanelHeader noButton />

      <PanelBody>
        <Container>
          <DefaultForm
            callbackSubmit={data => onSubmit({ data, setLoading, navigate })}
            loading={loading}
            inputs={inputs()}
          />
        </Container>
      </PanelBody>
    </Panel>
  )
}
