import * as S from './styles'
import * as S2 from '~/pages/CustomerServiceChat/Chat/styles'
import Timestamp from './Timestamp'
import { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import MediaPreview from './MediaPreview'
import ConnectedMessage from './ConnectedMessage'
import useChat from '~/pages/CustomerServiceChat/hooks/ChatHook'
import MessageMenu from '../../MessageMenu'
import { Button, Row } from 'reactstrap'

export default function DefaultMessage({
  conversation,
  toMe,
  getMedia,
  setSelectedMessage,
  contactNameOrNumber,
  isInAttend,
}) {
  const [connect, setConnect] = useState(
    false,
    // false ||
    //   (conversation && conversation?.type === 'image') ||
    //   (conversation && conversation?.type === 'sticker') ||
    //   (conversation && conversation?.type === 'audio'),
  )
  const { servicesState } = useChat()
  const chatUserNameOrNumber = useRef('Você')

  useEffect(() => {
    if (
      conversation?.referencedMessageOwner &&
      conversation?.referencedMessageOwner != 'Você'
    )
      chatUserNameOrNumber.current = contactNameOrNumber()
  }, [])
  const setUserMessageTextWithUserName = useCallback((text, inline = false) => {
    let userName = ''

    userName = servicesState?.find(doc => doc.phone == conversation?.to)?.name

    const newText = text.replace(/^[\s\S]*?\n/, '')

    return (
      <>
        {userName && (
          <span>
            <b>{userName}:&nbsp;</b>

            {!inline && <br></br>}

            {newText}
          </span>
        )}

        {!userName && <>{text}</>}
      </>
    )
  })

  function setSelectedMsg() {
    let msg = { id: conversation?._id, sender: 'Você' }

    if (conversation?.to == 'me') {
      msg.sender = contactNameOrNumber()
      msg.content = conversation?.text?.body
    } else {
      let startTextPos = (conversation?.text?.body?.indexOf('*:') ?? 0) + 2
      msg.content = conversation?.text?.body?.substring(startTextPos)
    }

    setSelectedMessage(msg)
  }

  return (
    <S.DefaultMessageContainer toMe={toMe}>
      <S.DefaultMessageContent toMe={toMe} type={conversation?.type}>
        {conversation?.messageId && (
          <S2.ReplyMessageSectionWithinMessage>
            <Row>
              <div className='col-12'>
                <div style={{ color: '#77c4d5', fontWeight: 'bold' }}>
                  {chatUserNameOrNumber.current}
                </div>
                <div>{conversation?.referencedMessageContent}</div>
              </div>
            </Row>
          </S2.ReplyMessageSectionWithinMessage>
        )}
        {isInAttend && (
          <MessageMenu setSelectedMessage={setSelectedMsg}></MessageMenu>
        )}
        {conversation?.phoneNumber?.search('group') > 0 && (
          <>
            <b>Grupo</b> <br />
          </>
        )}

        {conversation?.type === 'text' &&
          setUserMessageTextWithUserName(conversation?.text?.body)}

        {/* {!connect && (
          <>
            {(conversation?.type === 'document' ||
              conversation?.type === 'image' ||
              conversation?.type === 'sticker' ||
              conversation?.type === 'audio' ||
              conversation?.type === 'video') && (
              <MediaPreview
                downloadable
                title={conversation?.document?.fileName}
                onClick={() => setConnect(true)}
                type={conversation.type}
              />
            )}
          </>
        )} */}

        {!connect && (
          <ConnectedMessage message={conversation} getMedia={getMedia} />
        )}

        <div>
          {conversation?.image?.caption ??
            conversation?.video?.caption ??
            conversation?.document?.caption ??
            conversation?.caption}
        </div>
        <Timestamp message={conversation} toMe={toMe} />
      </S.DefaultMessageContent>
    </S.DefaultMessageContainer>
  )
}

DefaultMessage.propTypes = {
  conversation: {
    photo: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    lastMessage: PropTypes.string,
    read: PropTypes.bool,
    delivered: PropTypes.bool,
    sent: PropTypes.bool,
  },
  toMe: PropTypes.bool,
}
