import { mergeDuplicatedConversations } from '~/pages/CustomerServiceChat/functions'
import {
  CLEAR_CONVERSATIONS,
  SET_CONVERSATIONS,
  READ_CONVERSATIONS,
  CLEAR_CONVERSATIONS_STATE,
} from '../actions/conversationAction'

const initialState = {
  conversations: [],
}

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS: {
      const merged = mergeDuplicatedConversations({
        conversations: action.data,
      })

      return {
        ...state,
        conversations: merged.sort((a, b) => b.timestamp - a.timestamp),
      }
    }
    case CLEAR_CONVERSATIONS:
      return {
        ...state,
        conversations: [],
      }
    case READ_CONVERSATIONS: {
      let newConversations = [...state.conversations]

      const indexOfConversation = newConversations.findIndex(
        x => x.phoneNumber == action.data,
      )

      newConversations[indexOfConversation] = {
        ...newConversations[indexOfConversation],
        newMessage: false,
      }

      return {
        ...state,
        conversations: newConversations?.sort(
          (a, b) => b.timestamp - a.timestamp,
        ),
      }
    }
    case CLEAR_CONVERSATIONS_STATE: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default conversationReducer
