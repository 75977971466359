import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingBackofficeChatCenterData from '../../dataHooks/useHoldingBackofficeChatCenter'
import HeaderInPage from '../../components/HeaderInPage'
import ModalDefault from '~/components/ModalDefault'
import { useEffect, useState } from 'react'
import useModal from '~/hooks/useModal'
import DefaultForm from '~/components/DefaultForm'

export default function HoldingBackofficeChatCenter({
  filters,
  handleProfile,
  consultantOptions,
}) {
  const [handleShow, setHandleShow] = useState(false)
  const [customersFilter, setCustomersFilter] = useState(filters)

  useEffect(
    () =>
      setCustomersFilter(oldState => ({
        ...filters,
        consultant: oldState.consultant,
      })),
    [filters],
  )

  const { loading, dashboards } = useHoldingBackofficeChatCenterData({
    customersFilter,
    handleProfile,
  })

  const { showModal, hideModal } = useModal()

  const inputsRender = () => {
    const inputs = [
      {
        Header: 'Destino',
        accessor: 'state',
        width: 300,
      },
      {
        Header: 'Quantidade',
        accessor: 'count',
        width: 300,
      },
    ]

    return inputs
  }

  const cardSize = arr => {
    let size = '100%'

    switch (arr) {
      case 1:
        size = '100%'
        break
      case 2:
        size = '50%'
        break
      case 3:
        size = '33.33%'
        break
      case 4:
        size = '25%'
        break
      default:
        size = '20%'
        break
    }

    return size
  }

  const handleCustomerFilter = data => {
    setCustomersFilter(oldState => ({
      ...oldState,
      consultant: data?.consultant,
    }))

    hideModal()
  }

  const showFilters = () => {
    showModal({
      title: 'Filtros',
      body: () => (
        <div style={{ width: '100%' }}>
          <DefaultForm
            callbackSubmit={handleCustomerFilter}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'consultant',
                label: 'Consultor',
                placeholder: 'Selecione...',
                options: consultantOptions,
                isClearable: true,
                sm: 12,
                lg: 12,
              },
            ]}
          />
        </div>
      ),
    })
  }

  return (
    <Container>
      <HeaderInPage
        title='Chat Center'
        showFilters={showFilters}
        showIconFilter={true}
        consultant={customersFilter?.consultant?.label}
        handleProfile={handleProfile}
      />

      <Divisor margin='5px' />

      <AnalyticsDashboard
        data={dashboards?.bigNumbers}
        columnSizingPerRow={'33.33%'}
        handleShow={() => setHandleShow(oldState => !oldState)}
        loading={loading}
      />

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} xl={12}>
          <AnalyticsContainer title='Motivos de finalização'>
            <AnalyticsLineChart data={dashboards?.chatServiceEndReason} />
          </AnalyticsContainer>
        </Col>
      </Row>

      <b>*Dados obtidos a partir do dia 16/10/2024</b>
    </Container>
  )
}

const Container = styled.div``
