import { requestApiPostToCreate } from './functions'
import * as S from './style'
import DefaultForm from '~/components/DefaultForm'
import { inputCheck, inputs, inputsToExpand } from './inputs'

export const Header = ({ agencyPhoto }) => {
  return (
    <S.Header>
      <S.ImageButton>
        <img
          src={`data:image/*;base64,${agencyPhoto}`}
          alt='logo'
          style={{ maxWidth: '150px' }}
        />
      </S.ImageButton>
    </S.Header>
  )
}

export const Footer = ({ agencyPhoto }) => {
  return (
    <S.Footer>
      <img
        src={`data:image/*;base64,${agencyPhoto}`}
        alt='logo'
        style={{ maxWidth: '150px' }}
      />
    </S.Footer>
  )
}

const Product = ({ data }) => {
  return (
    <div
      style={{
        border: 'solid #bcbcbc 1px',
        borderRadius: '8px',
        padding: '8px',
        maxWidth: '461px',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>{data.productName}</h5>
      <img src={data.productPhoto} alt='logo' style={{ maxWidth: '100%' }} />
      <p
        style={{
          paddingTop: '8px',
          textAlign: 'justify',
          // border: 'solid red 1px',
        }}
      >
        {data.productDescription}
      </p>
    </div>
  )
}

export const MobileVersion = ({ data, token, ddi }) => {
  return (
    <div
      style={{
        maxWidth: '95%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h4>
        Obrigado pela oportunidade de lhe atender! <br /> Tenha a certeza que
        faremos de tudo ao nosso alcance para realizar seus sonhos nessa próxima
        viagem. Por favor informe os dados abaixo para continuarmos:
      </h4>

      {(data.productName || data.productPhone || data.productDescription) && (
        <Product data={data} />
      )}

      <DefaultForm
        callbackSubmit={data => requestApiPostToCreate({ data, token })}
        submitLabel='Enviar'
        inputs={
          data.expanded == true
            ? inputs(ddi).concat(inputsToExpand).concat(inputCheck)
            : inputs(ddi).concat(inputCheck)
        }
      />
    </div>
  )
}

export const DesktopVersion = ({ data, token, ddi }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <h5>
          Obrigado pela oportunidade de lhe atender! <br /> Tenha a certeza que
          faremos de tudo ao nosso alcance para realizar seus sonhos nessa
          próxima viagem. Por favor informe os dados abaixo para continuarmos:
        </h5>

        <DefaultForm
          callbackSubmit={data => requestApiPostToCreate({ data, token })}
          submitLabel='Enviar'
          inputs={
            data.expanded == true
              ? inputs(ddi).concat(inputsToExpand).concat(inputCheck)
              : inputs(ddi).concat(inputCheck)
          }
        />

        <p></p>
      </div>
      {(data.productName || data.productPhone || data.productDescription) && (
        <Product data={data} />
      )}
    </div>
  )
}

export const Body = ({ data, widthCurrent, token, ddi }) => {
  return (
    <>
      {widthCurrent <= 1000 ? (
        <MobileVersion data={data} token={token} ddi={ddi} />
      ) : (
        <DesktopVersion data={data} token={token} ddi={ddi} />
      )}
    </>
  )
}
