import SkeletonBottom from '../Chat/Skeleton/SkeletonBottom'
import SkeletonHeader from '../Chat/Skeleton/SkeletonHeader'
import SkeletonMessages from '../Chat/Skeleton/SkeletonMessages'
import { MessagesContainer } from '../Chat/styles'
import SkeletonContacts from '../Contacts/Skeleton/SkeletonContacts'
import InformationSkeleton from '../Information/Skeleton/SkeletonInformation'
import * as S from '../styles'

export default function ChatSkeleton() {
  return (
    <>
      <S.Container>
        <S.ContactsSection sm={2} md={3} lg={3}>
          <SkeletonContacts />
        </S.ContactsSection>
        <S.SectionChat sm={10} md={6} lg={6}>
          <SkeletonHeader />
          <MessagesContainer>
            <SkeletonMessages />
          </MessagesContainer>
          <SkeletonBottom />
        </S.SectionChat>
        <S.SectionInfo sm={2} md={3} lg={3}>
          <InformationSkeleton />
        </S.SectionInfo>
      </S.Container>
    </>
  )
}
