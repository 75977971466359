import { useEffect, useRef, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import { toast } from 'react-toastify'
import DownloadFile from '~/functions/DownloadFile'
import { apiRequest } from '~/apiService'
import LoadingContainer from '~/components/LoadingContainer'
import { TheProgressBar } from './style'

export default function ImportCustomer() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState({})
  const [importationInProgress, setImportationInProgress] = useState(false)
  const [porcentage, setPorcentage] = useState(0)
  const runner = useRef(importationInProgress)

  useEffect(() => {
    start()
  }, [importationInProgress])

  const start = () => {
    let timeoutId
    timeoutId = setTimeout(
      () => {
        getImportation()

        if (runner.current == true) start()
        else clearTimeout(timeoutId)
      },
      importationInProgress ? 20000 : 10,
    )
  }

  const getImportation = async () => {
    const response = await apiRequest('get', 'import/progress', {}, setLoading)
    setImportationInProgress(response.data.status)
    setPorcentage(response.data.percentage)
    runner.current = response.data.status
  }

  const onSubmit = async () => {
    const formData = new FormData()

    formData.append('file', file)

    const response = await apiRequest(
      'post',
      'import',
      formData,
      () => {},
      'Parabéns! Sua importação foi um sucesso e seus clientes estão sendo adicionados aos poucos a sua agência.',
    )

    setImportationInProgress(response.data.status)
    setPorcentage(response.data.percentage)
    runner.current = response.data.status
  }

  const onDownloadFile = async () => {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/import`,
      )

      DownloadFile(
        `data:application/octet-stream;base64,${data}`,
        'Platilha_De_Importacao_Tripmee.xlsx',
      )

      setLoading(false)
      toast.success('Download realizado com sucesso!')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <Container>
      {loading == true && porcentage == 0 && <LoadingContainer />}

      {importationInProgress == true && (
        <>
          <h1 className='page-header'>Sua importação está em andamento.</h1>
          <TheProgressBar
            value={porcentage}
            porcentage={porcentage}
          ></TheProgressBar>
        </>
      )}

      {loading == false &&
        (porcentage == 0 || porcentage == 100) &&
        importationInProgress == false && (
          <>
            <h1 className='page-header'>Importação de clientes</h1>

            <Panel>
              <PanelHeader title='Planilha modelo - Excel' noButton />
              <PanelBody>
                <DefaultForm
                  callbackSubmit={onDownloadFile}
                  submitLabel={'Baixar'}
                  disabled={loading}
                  inputs={[
                    {
                      type: 'text',
                      name: 'text',
                      value: 'Platilha_De_Importacao_Tripmee.xlsx',
                      label: '',
                      disabled: true,
                      sm: 12,
                    },
                  ]}
                />
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader title='Regras IMPORTANTES' noButton />
              <PanelBody>
                <ul
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  <li>O cliente obrigatoriamente deve ter nome completo.</li>

                  <li>
                    Se o cliente tiver email ou telefone, os mesmos não podem
                    estar duplicados nem cadastrados na plataforma.
                  </li>

                  <li>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#700',
                      }}
                    >
                      ** Todos os clientes inseridos na tabela que não seguirem
                      essas regras serão ignorados! **
                    </p>
                  </li>
                </ul>
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader
                title='Importe a planilha preenchida com seus clientes'
                noButton
              />
              <PanelBody>
                <DefaultForm
                  callbackSubmit={onSubmit}
                  submitLabel={'Importar'}
                  disabled={loading}
                  inputs={[
                    {
                      type: 'file',
                      name: 'inputFileAsync',
                      label: 'Selecione o arquivo de planilha',
                      onChange: props => setFile(props.target.files[0]),
                      sm: 12,
                    },
                  ]}
                />
              </PanelBody>
            </Panel>
          </>
        )}
    </Container>
  )
}
