export const SET_CONTACTS = 'SET_CONTACTS'
export const CLEAR_CONTACT_STATE = 'CLEAR_CONTACT_STATE'

export const setContacts = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CONTACTS,
  })
}

export const clearContactState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_CONTACT_STATE,
  })
}
