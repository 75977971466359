import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Select from '~/components/Select'
import { GetAxios } from '~/services/api'

export default function ExperiencesProposalActivitySelect({
  name,
  onChange,
  defaultValue,
}) {
  const { axiosApis } = GetAxios()
  const [experienceOptions, setExperienceOptions] = useState([])
  const [experienceSelected, setExperienceSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        const proposalId = location.state?.id

        const url = `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getbyproposalid/${proposalId}`

        const { data } = await axiosApis.get(url)

        const newOptions = data.map(item => {
          return {
            value: item.code,
            label: `${item.title}, ${item.city}, ${item.category}`,
            sublabel: item.title,
            category: item.category,
            city: item.city,
            type: item.type,
          }
        })

        setExperienceOptions(newOptions)

        return setLoading(false)
      } catch (error) {
        console.log(error)

        return setLoading(false)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: experienceSelected.map(x => x.value),
        },
      })
    }
  }, [experienceSelected])

  useEffect(() => {
    if (defaultValue) {
      const newExperiencesSelecteds = experienceOptions.filter(x =>
        defaultValue.find(y => y === x.value),
      )

      setExperienceSelected(newExperiencesSelecteds)
    } else {
      setExperienceSelected([])
    }
  }, [experienceOptions])

  async function handleSelect(e) {
    setExperienceSelected(e.target.value)
  }

  return (
    <Select
      disabled={loading}
      isLoading={loading}
      name={name}
      label={'Experiências'}
      placeholder={'Selecione...'}
      options={experienceOptions}
      value={experienceSelected}
      onChange={handleSelect}
      isClearable
      isMulti
      formatOptionLabel={props => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <b>{props.sublabel}</b>

            <span>{props.city}...</span>

            <p>
              Categoria: <b>{props.category}</b>
            </p>
          </div>
        )
      }}
    />
  )
}
