import DefaultForm from '~/components/DefaultForm'
import {
  Body,
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  ImageButton,
} from './style'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { inputs } from './inputs'
import { getResources, onSubmit } from './functions'

export default function longForm() {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [ddiOptions, setDdiOptions] = useState({})

  document.title = 'Plataforma CRM especializada em agências de viagens'

  useEffect(() => {
    getResources(params.token, setImage, setDdiOptions, setLoading)
  }, [])

  return (
    <Div>
      <CoverHeader>
        <Header>
          <ImageButton>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </ImageButton>
        </Header>
      </CoverHeader>

      <Container>
        <>
          <Body id='form'>
            <h3>Formulário de Cliente</h3>
            <DefaultForm
              callbackSubmit={data => onSubmit(data, params.token, setLoading)}
              submitLabel='Enviar'
              backgroundColor='#D54A26'
              color='#fff'
              inputs={inputs(ddiOptions)}
              loading={loading}
            />
          </Body>
        </>
      </Container>
      <br></br>

      <br></br>

      <Footer>
        <div>
          <div>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </div>
        </div>
      </Footer>
    </Div>
  )
}
