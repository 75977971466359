import styled from 'styled-components'

export const Capsule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 8px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const B = styled.b`
  color: ${props =>
    props.handleProfile ? props.theme.colors.primary : '#7f2ec2'};
`
