import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { Container } from './styles'
import Form from './components'

export default function ProductDetails() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  return (
    <Container>
      <PageTitle title='Detalhes do produto' />
      <Form setLoading={setLoading} navigate={navigate} loading={loading} />
    </Container>
  )
}
