import { useContext, useEffect, useState } from 'react'
import { rules } from '~/constants/holdingBackofficeRules'
import context from '~/context/createContext'
import { useSelector } from 'react-redux'
import { getInstanceStatus } from '~/services/chat'

export default function useHoldingMenus() {
  const [menus, setMenus] = useState([])
  const { handleProfile, showSalesStats, setHandleProfile, agency } =
    useContext(context)
  const auth = useSelector(state => state.auth)
  const [showConnectWhatsapp, setShowConnectWhatsapp] = useState(false)

  useEffect(() => {
    async function getSource() {
      try {
        if (auth.tenant && agency?.enableChatCenter) {
          const status = await getInstanceStatus(auth.tenant)

          if (status.connected === true) {
            setShowConnectWhatsapp(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    getSource()

    return () => {}
  }, [agency])

  useEffect(() => {
    async function updateMenus() {
      let newMenus = []

      newMenus.push(rules.home.menu)
      if (handleProfile) rules.home.menu.title = 'Resumo'

      newMenus.push(rules.customers.menu)

      if (handleProfile) {
        if (showSalesStats) {
          newMenus.push(rules.sales.menu)
        }
      } else {
        newMenus.push(rules.sales.menu)
      }

      newMenus.push(rules.proposals.menu)

      if (showConnectWhatsapp) {
        newMenus.push(rules.chatcenter.menu)
      }

      setMenus(newMenus)
    }

    updateMenus()
  }, [handleProfile, showConnectWhatsapp])

  return { menus }
}
