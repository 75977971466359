import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { Container } from './styles'
import { getData } from './functions'
import Form from './components'

export default function ProductDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const [defaultData, setDefaultData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData({ location, setLoading, setDefaultData })
  }, [])

  return (
    <Container>
      <PageTitle title='Detalhes do produto' />
      <Form
        setLoading={setLoading}
        navigate={navigate}
        defaultData={defaultData}
        loading={loading}
      />
    </Container>
  )
}
