export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION'
export const CLEAR_CURRENT_CONVERSATION = 'CLEAR_CURRENT_CONVERSATION'
export const CLEAR_CURRENT_CONVERSATION_STATE =
  'CLEAR_CURRENT_CONVERSATION_STATE'

export const setCurrentConversationAction = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CURRENT_CONVERSATION,
  })
}

export const clearCurrentConversationAction = (data, dispatch) => {
  dispatch({
    data: data,
    type: CLEAR_CURRENT_CONVERSATION,
  })
}

export const clearCurrentConversationState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_CURRENT_CONVERSATION_STATE,
  })
}
