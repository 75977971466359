import { apiRequest } from '~/apiService'

export const onSubmit = async ({ data, setLoading, setData }) => {
  const response = await apiRequest('get', 'product', data, setLoading)

  setData(response.data)
}

export const onDelete = async ({ props, setLoading, setData }) => {
  const response = await apiRequest(
    'delete',
    `product/${props.row.original.value}`,
    {},
    setLoading,
  )

  setData(response.data)
}
