import { useEffect, useState, Component } from 'react'
import { GetAxios } from '~/services/api'
import Select from '../Select'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { createFilter } from 'react-select'

const height = 35
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  }
}

export default function LargeListSelect({
  name,
  label,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  isClearable,
  isMulti,
  required,
  options,
  ...rest
}) {
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    setValue(defaultValue ?? null)
  }, [defaultValue])

  const handleChange = e => {
    setValue(e)
    onChange({
      target: {
        name: name,
        value: e,
      },
    })
  }

  return (
    <div>
      <SalesReleaseSelectRWindow>
        <Select
          filterOption={createFilter({ ignoreAccents: false })}
          label={label + `${required ? ' * ' : ''}`}
          placeholder={placeholder ?? ''}
          components={{ MenuList }}
          className='basic-single col-12'
          classNamePrefix='select'
          defaultValue={defaultValue}
          // isClearable={false}
          isClearable={isClearable}
          isSearchable={true}
          isMulti={isMulti}
          name={name}
          options={options}
          disabled={disabled}
          onChange={props => {
            console.log(props)
            console.log(onChange)
            onChange(props, onChange)
          }}
          // {...register(name)}
          // onChange={e => {
          //   if (setValue) setValue(name, e)
          // }}
          required={required}
          {...rest}
        />
      </SalesReleaseSelectRWindow>
    </div>
  )
}

export const SalesReleaseSelectRWindow = styled.div`
  .select__menu {
    overflow: auto;
    width: 300px;
    z-index: 3;
  }

  .select__menu > div > div {
    width: max-content !important;
  }

  .select__option {
    white-space: nowrap;
    display: inline-table;
  }

  .select__control {
    min-height: 36px !important;
    height: 36px !important;
  }

  width: 100%;
`
