import LoadingContainer from '~/components/LoadingContainer'
import { FormatValueBRL } from '~/functions/FormatValue'
import * as s from './styles'

function AnalyticsDashboard({
  data = [],
  columnSizingPerRow = '50%',
  handleShow,
  loading,
}) {
  return (
    <s.container>
      {data.map((e, i) => {
        return (
          <s.item key={i} columnSizingPerRow={columnSizingPerRow}>
            <s.itemContent>
              {loading && (
                <>
                  <LoadingContainer />
                </>
              )}

              {!loading && e.type != 'button' && (
                <>
                  <s.label>{e.label}</s.label>

                  <s.values>
                    <s.value>
                      {e.currency ? FormatValueBRL(e.value) : e.value}
                    </s.value>

                    <s.subLabel>{e.subLabel}</s.subLabel>
                  </s.values>
                </>
              )}

              {!loading && e.type == 'button' && (
                <div
                  onClick={() => handleShow()}
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <p
                    style={{
                      borderBottom: '1px solid #ccc',
                      width: '100%',
                      padding: '8px',
                      margin: '0px',
                      fontWeight: '500',
                    }}
                  >
                    {e.label}
                  </p>

                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'end',
                      fontSize: '1.6em',
                      fontWeight: 'bold',
                      padding: '8px',
                    }}
                  >
                    <span>
                      {e.currency ? FormatValueBRL(e.value) : e.value}
                    </span>

                    <span>{e.subLabel}</span>
                  </div>
                </div>
              )}
            </s.itemContent>
          </s.item>
        )
      })}
    </s.container>
  )
}

const getDashboardPlaceholders = count => {
  const res = []

  for (let i = 0; i < count; i++) {
    res.push({
      loading: true,
    })
  }

  return res
}

export { getDashboardPlaceholders }

export default AnalyticsDashboard
