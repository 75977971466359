import {
  CLEAR_CURRENT_CONVERSATION,
  CLEAR_CURRENT_CONVERSATION_STATE,
  SET_CURRENT_CONVERSATION,
} from '../actions/currentConversationAction'

const initialState = {
  currentConversation: undefined,
}

const currentConversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.data,
      }
    case CLEAR_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: undefined,
      }
    case CLEAR_CURRENT_CONVERSATION_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default currentConversationReducer
