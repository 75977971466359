import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import DefaultForm from '~/components/DefaultForm'
import { useLocation, useNavigate } from 'react-router-dom'
import PageTitle from '~/components/PageTitle'
import { useEffect, useState, useRef } from 'react'
import { Container } from '~/styles/container'
import { proposalInputs } from './inputs'
import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import Modal from '../Modal'
import { inputs } from '../Modal/Inputs'
import ModalHotel from '../Modal/hotel'
import ModalAir from '../Modal/air'
import ModalNautical from '../Modal/nautical'
import { getDateFormated } from '~/functions/Date'
import CreateActivity from '../Modal/Activity/Create'
import EditActivity from '../Modal/Activity/Edit'
import ModalActivity from '../components/Modal'
import useAxios from '~/hooks/useAxios'

const pagePropsDefault = {
  menu: undefined,
  modal: undefined,
  toCreate: undefined,
  toEdit: undefined,
}

export default function ProposalDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ ...location.state })
  const [observation, setObservation] = useState([])
  const [pageProps, setPageProps] = useState(pagePropsDefault)
  const { apiClient } = useAxios()
  const citiesList = useRef([])
  const [showModal, setShowModal] = useState({
    destiny: {
      id: 0,
      title: 'Destinos',
      openModal: false,
      content: 'proposalDestiny',
    },
    flight: {
      id: 0,
      title: 'Aéreos',
      openModal: false,
      content: 'proposalFlight',
    },
    nautical: {
      id: 0,
      title: 'Náuticos',
      openModal: false,
      content: 'proposalNautical',
    },
    train: {
      id: 0,
      title: 'Trens',
      openModal: false,
      content: 'proposalTrain',
    },
    transfer: {
      id: 0,
      title: 'Transfers',
      openModal: false,
      content: 'proposalTransfer',
    },
    hotel: {
      id: 0,
      title: 'Hotéis',
      openModal: false,
      content: 'proposalHotel',
    },
    activity: {
      id: 0,
      title: 'Atividades',
      openModal: false,
      content: 'proposalActivity',
    },
    insurance: {
      id: 0,
      title: 'Seguros',
      openModal: false,
      content: 'proposalInsurance',
    },
    otherService: {
      id: 0,
      title: 'Outros serviços',
      openModal: false,
      content: 'proposalOtherService',
    },
    ticket: {
      id: 0,
      title: 'Ingressos',
      openModal: false,
      content: 'proposalTicket',
    },
    vehicleRental: {
      id: 0,
      title: 'Locações veiculares',
      openModal: false,
      content: 'proposalVehicleRental',
    },
  })

  useEffect(() => {
    if (location.state.fromCreatePage == undefined) {
      getOptions()
      getProposal()
    }
  }, [])

  useEffect(async () => {
    let result
    result = await apiClient.options('/proposal/airports')
    if (result.status === 200) citiesList.current = result.data
  }, [])

  const getProposal = async () => {
    const result = await apiRequest(
      'get',
      `proposal/${location.state.id}`,
      {},
      setLoading,
    )

    setData(result.data)
  }

  const getOptions = async () => {
    const response = await apiRequest('options', `observation`)

    if (response.error == false) setObservation(response?.data)
  }

  const onUpdate = async data => {
    const formData = new FormData()

    for (let key in data) {
      if (data[key] != null) {
        if (key == 'proposalStatus') formData.append(key, data[key]?.value)
        formData.append(key, data[key])
      }
    }

    formData.set('origin', data?.origin.label)
    formData.append('originId', data?.origin.value)
    formData.append('id', location?.state?.id)

    const result = await apiRequest('put', 'proposal', formData, setLoading)

    if (result.error) {
      toast.info(result?.erroMessage)
    } else {
      toast.success(result?.data?.message)
      navigate('/proposal')
    }
  }

  const onDelete = async () => {
    const result = await apiRequest(
      'delete',
      `proposal/${location?.state?.id}`,
      {},
      setLoading,
    )

    if (!result.data.error) navigate('/proposal')

    !result.error && toast.success(result?.data)

    result.error && toast.info(result?.erroMessage)
  }

  const onValidate = async data => {
    if (data.origin.value == undefined && data.origin.label == undefined) {
      toast.info(
        'Para criar uma proposta é necessário preencher o campo de origem',
      )
      return
    } else if (data.dateFinal < data.dateInitial) {
      toast.info('Data final não pode ser inferior a data inicial')
      return
    } else {
      await onUpdate(data)
    }
  }

  const whichModalToOpen = () => {
    const getMatrixFromObject = Object.entries(showModal)
    const findFirstTrue = getMatrixFromObject?.find(x => x[1].openModal == true)
    const name = findFirstTrue?.[0]

    return name
  }

  const handle = () => {
    setShowModal(state => {
      const aux = { ...state }

      aux[whichModalToOpen()] = {
        ...aux[whichModalToOpen()],
        openModal: !aux[whichModalToOpen()].openModal,
        id: 0,
      }

      return aux
    })
  }

  const onRemove = async (origin, id, content) => {
    const result = await apiRequest(
      'delete',
      `proposal/${origin}/${id}`,
      {},
      setLoading,
    )

    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })

    !result.error && toast.success(result?.data?.message)

    result.error && toast.info(result?.erroMessage)
  }

  const reloadList = (content, result) => {
    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })
  }

  const onClone = async (route, id, content) => {
    const result = await apiRequest(
      'post',
      `proposal/clone-${route}`,
      { id: id, proposalId: location.state.id },
      setLoading,
    )

    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })

    !result.error && toast.success(result?.data?.message)

    result.error && toast.info(result?.erroMessage)
  }

  const closeModal = reload => {
    setPageProps(pagePropsDefault)
    handle()

    async function getSource() {
      try {
        setLoading(true)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if (reload) {
      getSource()
    }
  }

  return (
    <>
      <Container>
        <PageTitle title='Detalhes da Proposta' />
        <Panel>
          <PanelHeader noButton />

          {whichModalToOpen() != 'hotel' &&
            whichModalToOpen() != 'flight' &&
            whichModalToOpen() != 'nautical' &&
            whichModalToOpen() != 'activity' &&
            showModal[whichModalToOpen()]?.openModal && (
              <Modal
                title={showModal[whichModalToOpen()]?.title}
                id={showModal[whichModalToOpen()]?.id}
                content={showModal[whichModalToOpen()]?.content}
                setData={setData}
                proposalId={location?.state?.id}
                origin={whichModalToOpen()}
                handle={handle}
                inputs={inputs[whichModalToOpen()]}
              />
            )}

          {whichModalToOpen() == 'activity' &&
            showModal[whichModalToOpen()]?.id == 0 &&
            showModal[whichModalToOpen()]?.openModal && (
              <ModalActivity title={`Adicionar Atividade`} onClose={closeModal}>
                <CreateActivity onClose={closeModal} reloadList={reloadList} />
              </ModalActivity>
            )}

          {whichModalToOpen() == 'activity' &&
            showModal[whichModalToOpen()]?.id != 0 &&
            showModal[whichModalToOpen()]?.openModal && (
              <ModalActivity title={`Editar Atividade`} onClose={closeModal}>
                <EditActivity
                  onClose={closeModal}
                  id={showModal[whichModalToOpen()]?.id}
                  onRemove={onRemove}
                  reloadList={reloadList}
                />
              </ModalActivity>
            )}

          {whichModalToOpen() == 'hotel' &&
            showModal[whichModalToOpen()]?.openModal && (
              <ModalHotel
                title={showModal[whichModalToOpen()]?.title}
                id={showModal[whichModalToOpen()]?.id}
                content={showModal[whichModalToOpen()]?.content}
                setData={setData}
                proposalId={location?.state?.id}
                origin={whichModalToOpen()}
                handle={handle}
                inputs={inputs[whichModalToOpen()]}
              />
            )}

          {whichModalToOpen() == 'flight' &&
            showModal[whichModalToOpen()]?.openModal && (
              <ModalAir
                title={showModal[whichModalToOpen()]?.title}
                id={showModal[whichModalToOpen()]?.id}
                content={showModal[whichModalToOpen()]?.content}
                setData={setData}
                proposalId={location?.state?.id}
                origin={whichModalToOpen()}
                handle={handle}
                inputs={inputs[whichModalToOpen()]}
                onClone={onClone}
                citiesList={citiesList.current}
              />
            )}

          {whichModalToOpen() == 'nautical' &&
            showModal[whichModalToOpen()]?.openModal && (
              <ModalNautical
                title={showModal[whichModalToOpen()]?.title}
                id={showModal[whichModalToOpen()]?.id}
                content={showModal[whichModalToOpen()]?.content}
                setData={setData}
                proposalId={location?.state?.id}
                origin={whichModalToOpen()}
                handle={handle}
                inputs={inputs[whichModalToOpen()]}
                onClone={onClone}
              />
            )}

          <PanelBody>
            <DefaultForm
              callbackSubmit={onUpdate}
              callbackDelete={onDelete}
              onValidate={onValidate}
              defaultValue={data}
              disabled={loading}
              loading={loading}
              inputs={proposalInputs(
                loading,
                setShowModal,
                onRemove,
                onClone,
                observation,
              )}
            />
          </PanelBody>
        </Panel>
      </Container>
    </>
  )
}
