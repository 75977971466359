import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import PageTitle from '~/components/PageTitle'
import Table from '~/components/Table'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import IconButton from '~/components/IconButton'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'

export default function BackofficeBranchsDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [data, setData] = useState({
    agency: location.state.agency,
    branchs: [],
  })

  useEffect(async () => {
    await getSource()

    return () => {
      setData({
        agency: undefined,
        branchs: [],
      })
    }
  }, [])

  async function getSource() {
    try {
      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/backofficebranch/${data.agency.id}`,
      )

      setData({
        ...data,
        ...res.data,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function onDelete(e) {
    try {
      await axiosApis.delete(
        `${process.env.REACT_APP_ENDPOINT_APIS}/backofficebranch/${e.id}`,
      )

      await getSource()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <PageTitle title={data.agency?.name ?? 'Agência'} head={'h1'} />

      <Panel>
        <PanelHeader title={'Filiais'} noButton>
          <LinkRegisterNewTraveler
            to={'/backoffice-branchs/create'}
            state={data}
          >
            Nova filial
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data.branchs}
            columns={[
              {
                Header: 'Nome da agência',
                accessor: 'name',
                width: 250,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 250,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 150,
              },
              {
                Header: 'Código filial',
                accessor: 'code',
                width: 200,
              },
              {
                Header: '',
                accessor: 'delete',
                Cell: props => {
                  return (
                    <IconButton
                      id={`delete-${props.row.index}`}
                      type={'remove'}
                      onClick={() => onDelete(props.row.original)}
                    />
                  )
                },
                width: 80,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``
