import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Col } from 'reactstrap'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import IconButton from '~/components/IconButton'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDateFormated } from '~/functions/Date'
import Table from '~/components/Table'
import ShowInfo from './showInfo'
import ModalLink from './modalLinks'
import useModal from '~/hooks/useModal'
import { Rating } from 'primereact/rating'
import { toast } from 'react-toastify'
import { RegisterNewButton2 } from '~/components/Button/styles'
import ShowDependents from './dependents'
import RelationshipDetails from '../Details'
import RelationshipCreate from '../Create'

export default function RelationshipCustomers() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [customers, setCustomers] = useState([])
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const { showModal, hideModal } = useModal()
  const [tableProps, setTableProps] = useState({
    page: 1,
    limit: 10,
    count: 0,
    filters: {},
  })

  useEffect(() => {
    onSubmit({}, 0, tableProps.limit)
    getOptionsTag()
  }, [])

  const getOptionsTag = async () => {
    try {
      const { data } = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags`,
      )

      setTags(data)
    } catch (error) {
      toast.info('Não foi possível obter as tags da agência')
    }
  }

  async function showInfo(traveler) {
    showModal({
      title: 'Tags',
      body: () => <ShowInfo traveler={traveler} />,
      width: '30%',
    })
  }

  async function showLink(traveler) {
    showModal({
      title: 'Link de cadastro',
      body: () => <ModalLink traveler={traveler} />,
      width: '50%',
    })
  }

  async function showDependentsModal(name, id) {
    showModal({
      title: `Dependentes de ${name}`,
      body: () => <ShowDependents id={id} />,
      width: '85%',
    })
  }

  async function showDetails(e) {
    showModal({
      title: 'Detalhes',
      body: () => (
        <RelationshipDetails
          customerId={e.id}
          hideModal={hideModal}
          onUpdate={onSubmit}
          tableProps={tableProps}
          isEnterprise={e.isEnterprise}
        />
      ),
    })
  }

  async function ShowModalToNewCustomer() {
    showModal({
      title: 'Novo cliente',
      body: () => (
        <RelationshipCreate
          hideModal={hideModal}
          onUpdate={onSubmit}
          tableProps={tableProps}
        />
      ),
    })
  }

  async function AttRating(data, antdata) {
    try {
      setLoading(true)

      const formData = new FormData()

      if (antdata.rating != data) {
        formData.append('id', antdata.id)
        formData.append('rating', data)
        formData.append('ratingChangeOnly', true)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/relationship/edit/rating`,
          formData,
        )

        await onSubmit(
          tableProps.filters,
          (tableProps.page - 1) * tableProps.limit,
          tableProps.limit,
        )
      }

      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.info(error.response.data)
      }

      setLoading(false)
    }
  }

  async function callbackOnTraveler(item) {
    try {
      setLoading(true)

      if (window.confirm(`Deseja criar o viajante: ${item.fullName}?`)) {
        const formData = new FormData()

        formData.append('id', item.id)

        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/customer`,
          formData,
        )

        setLoading(true)

        toast.success('Viajante criado com sucesso!')

        await onSubmit(
          tableProps.filters,
          (tableProps.page - 1) * tableProps.limit,
          tableProps.limit,
        )
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)

      if (err?.response?.data) {
        toast.info(err.response.data)
      } else {
        toast.info(
          'Não foi possível criar o viajante, por favor tente novamente mais tarde.',
        )
      }
    }
  }

  // useEffect(() => {
  //   onSubmit({}, 0, tableProps.limit)
  // }, [])

  async function onSubmit(data, skip, take) {
    try {
      setLoading(true)

      const params = {
        ...data,
        skip,
        take,
        isTraveler: !!data?.traveler,
        fullName: data?.fullName ?? null,
        email: data?.email ?? null,
        cpfCnpj: data?.cpfCnpj ?? null,
        phone: data?.phone ?? null,
        prospection: data?.prospection?.value ?? null,
        isEnterprise: data?.isEnterprise?.value ?? null,
        contactName: data?.contactName ?? null,
        contactPhone: data?.contactPhone ?? null,
        contactEmail: data?.contactEmail ?? null,
        tags:
          String(data?.tags?.map(tag => tag.label)) == 'undefined'
            ? null
            : String(data?.tags?.map(tag => tag.label)),
      }

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer`,
        {
          params: {
            ...params,
          },
        },
      )

      setTableProps({
        ...tableProps,
        filters: data ?? {},
        page: response.data.page,
        count: response.data.count,
        limit: response.data.limit,
      })

      setCustomers(response.data.items)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onChangePage(e) {
    await onSubmit(
      tableProps.filters,
      tableProps.limit * (e - 1),
      tableProps.limit,
    )
  }

  async function onChangeLimit(e) {
    await onSubmit(tableProps.filters, 0, e)
  }

  // async function showDetails(e) {
  //   showModal({
  //     title: 'Detalhes',
  //     body: () => <RelationshipDetails customerId={e.id} />,
  //   })
  // }

  // async function ShowModalToNewCustomer() {
  //   showModal({
  //     title: 'Novo cliente',
  //     body: () => <RelationshipCreate />,
  //   })
  // }

  async function showLancamento(e) {
    navigate('/relationship-customers/enrollment', { state: { customers: e } })
  }

  const toOperator = [
    {
      Header: 'Consultor de viagens',
      accessor: 'isAgent',
      Cell: props => {
        return props.row.original.isAgent ? 'Sim' : 'Não'
      },
      width: 160,
    },
    {
      Header: 'Agência',
      accessor: 'fullName',
      width: 175,
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      width: 150,
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      width: 120,
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 150,
    },
    {
      Header: 'Aniversário',
      accessor: 'birthdayDate',
      width: 100,
      Cell: props => {
        if (!props.row.original.birthdayDate) return null

        return (
          <span>
            {getDateFormated(props.row.original.birthdayDate, 'DD/MM')}
          </span>
        )
      },
    },
    {
      Header: 'Cidade',
      accessor: 'city',
      width: 120,
    },
    {
      Header: 'Nome do Contato',
      accessor: 'contactName',
      width: 150,
    },
    {
      Header: 'Telefone do Contato',
      accessor: 'contactPhone',
      width: 150,
    },
    {
      Header: 'Email do Contato',
      accessor: 'contactEmail',
      width: 150,
    },
    {
      Header: 'Lead',
      accessor: 'prospection',
      width: 60,
      Cell: props => {
        const parse = props.row.original.prospection ? 'Sim' : 'Não'
        return parse
      },
    },
    {
      Header: 'Empresa',
      accessor: 'isEnterprise',
      width: 60,
      Cell: props => {
        const parse = props.row.original.isEnterprise ? 'Sim' : 'Não'
        return parse
      },
    },
    {
      Header: 'Consultor',
      accessor: 'agent',
      width: 120,
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          // <LinkEdit to={'details'} state={{ customers: props.row.original }}>
          //   Detalhes
          // </LinkEdit>
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'tags',
      Cell: props => {
        return (
          <IconButton
            id={props.row.id}
            iconOverride={'fas fa-solid fa-tags'}
            tooltipOverride={'Tags'}
            type={'tags'}
            onClick={() => showInfo(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'dependents',
      Cell: props => {
        return (
          <div>
            <IconButton
              id={props.row.index}
              type={'file'}
              tooltipOverride={'Dependentes'}
              iconOverride={'fas fa-user-friends'}
              onClick={props =>
                showDependentsModal(
                  props.row.original.fullName,
                  props.row.original.id,
                )
              }
            />
          </div>
        )
      },
      width: 20,
    },
  ]

  const toAgency = [
    {
      Header: 'Nome do Cliente',
      accessor: 'fullName',
      width: 200,
      Cell: props => {
        return (
          <>
            {props.row.original.backofficeCustomerCode && (
              <>
                <b>(Wintour)</b>

                <br />
              </>
            )}

            <span>{props.row.original.fullName}</span>
          </>
        )
      },
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      width: 200,
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      width: 120,
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 150,
    },
    {
      Header: 'Cidade',
      accessor: 'city',
      width: 90,
    },
    {
      Header: 'Tags',
      accessor: 'tag',
      width: 140,
    },
    {
      Header: 'Lead',
      accessor: 'prospection',
      width: 75,
      Cell: props => {
        const parse = props.row.original.prospection ? 'Sim' : 'Não'
        return parse
      },
    },
    {
      Header: 'Empresa',
      accessor: 'isEnterprise',
      width: 60,
      Cell: props => {
        const parse = props.row.original.isEnterprise ? 'Sim' : 'Não'
        return parse
      },
    },
    {
      Header: 'Classificação',
      accessor: '',
      width: 140,
      Cell: props => {
        return (
          <div>
            {user.role == 'admin' ? (
              <Rating
                cancel={false}
                value={props.row.original.rating}
                onChange={e => AttRating(e.value, props.row.original)}
                disabled={loading}
              />
            ) : (
              <Rating
                cancel={false}
                value={props.row.original.rating}
                disabled={loading}
              />
            )}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          <IconButton
            id={props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'tags',
      Cell: props => {
        return (
          <IconButton
            id={props.row.id}
            iconOverride={'fas fa-solid fa-tags'}
            tooltipOverride={'Tags'}
            type={'tags'}
            onClick={() => showInfo(props.row.original)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'traveler',
      Cell: props => {
        return (
          <div>
            {props.row.original.travelerId == 0 && (
              <IconButton
                id={props.row.index}
                type={'create'}
                tooltipOverride={'Criar viajante'}
                iconOverride={'far fa-id-card'}
                colorOverride={'#333'}
                style={{ fontSize: '12px' }}
                onClick={() => callbackOnTraveler(props.row.original)}
              />
            )}
          </div>
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'docs',
      Cell: props => {
        return (
          <div>
            {props.row.original.travelerId != 0 && (
              <IconButton
                id={props.row.original.id}
                type={'file'}
                onClick={() =>
                  navigate('/travelers/documents', {
                    state: {
                      traveler: {
                        ...props.row.original,
                        fromCustomer: true,
                      },
                    },
                  })
                }
              />
            )}
          </div>
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'dependents',
      Cell: props => {
        return (
          <div>
            <IconButton
              id={props.row.index}
              type={'file'}
              tooltipOverride={'Dependentes'}
              iconOverride={'fas fa-user-friends'}
              onClick={() =>
                showDependentsModal(
                  props.row.original.fullName,
                  props.row.original.id,
                )
              }
            />
          </div>
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'lancamento',
      Cell: props => {
        return (
          <div>
            {user.agencyName == 'Wee Travel' ? (
              <IconButton
                id={props.row.index}
                type={'lançamento'}
                tooltipOverride={'Lançamento'}
                iconOverride={'fas fa-hand-holding-usd'}
                onClick={() => showLancamento(props.row.original)}
              />
            ) : (
              <p></p>
            )}
          </div>
        )
      },
      width: 20,
    },
  ]

  const saveAsExcelFile = (buffer, fileName) => {
    const fileSaver = import('file-saver')

    fileSaver.then(module => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
        )
      }
    })
  }

  const exportExcel = async () => {
    const xlsx = import('xlsx')

    const customersForExportation = [...customers]

    customersForExportation.forEach(x => {
      delete x.token
      delete x.id
      delete x.degreeOfKinship
      delete x.dependentOn
      delete x.intoTraveler
      delete x.agencyId
      delete x.avatarData
      delete x.avatar
      delete x.isAgent
      delete x.agent
      delete x.agentId
      delete x.ratingChangeOnly
      delete x.travelerId
      delete x.cityId
      delete x.belongingAgency
      delete x.n
      delete x.compl
      delete x.company
      delete x.yellowFeverQuant
      delete x.preference
      delete x.fileRG
      delete x.fileVisa
      delete x.filePassaportBrazilian
      delete x.fileYellowFever
      delete x.fileforeignPassport
    })

    xlsx.then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(customersForExportation)
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, 'Tripmee_Customers')
    })
  }

  return (
    <Container>
      <h1 className='page-header'>
        Lista de Clientes
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela onde você visualiza, edita e inclui novos clientes.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={data => onSubmit(data, 0, tableProps.limit)}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome do Cliente',
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'email',
                label: 'E-mail',
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone',
                maxLength: 15,
                sm: 12,
                lg: 2,
              },
              {
                type: 'text',
                name: 'cpfCnpj',
                label: 'CPF/CNPJ',
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'tags',
                label: 'Tags',
                options: tags,
                isMulti: true,
                placeholder: 'Selecione...',
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'prospection',
                label: 'Lead',
                options: [
                  { label: 'Selecione...', value: null },
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ],
                placeholder: 'Selecione...',
                sm: 12,
                lg: 2,
              },
              {
                type: 'select',
                name: 'isEnterprise',
                label: 'Empresa',
                options: [
                  { label: 'Selecione...', value: null },
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ],
                placeholder: 'Selecione...',
                sm: 12,
                lg: 2,
              },
              {
                type: 'check',
                name: 'traveler',
                label: 'É viajante?',
                sm: 12,
                lg: 1,
              },
            ]}
          />

          <Col sm={12} lg={4} xl={3}></Col>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}>
          <RegisterNewButton2 onClick={() => ShowModalToNewCustomer()}>
            Novo cliente
          </RegisterNewButton2>
          <div>
            <RegisterNewButton2
              onClick={() => {
                showLink()
              }}
              disabled={loading}
            >
              Gerar link
            </RegisterNewButton2>
          </div>

          <div>
            {user.role == 'admin' && customers.length > 0 && (
              <RegisterNewButton2
                onClick={() => exportExcel()}
                disabled={loading}
              >
                Exportar
              </RegisterNewButton2>
            )}
          </div>
        </PanelHeader>

        <PanelBody>
          <Table
            data={customers}
            columns={user.isOperator == true ? toOperator : toAgency}
            count={tableProps.count}
            actualPage={tableProps.page}
            limit={tableProps.limit}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
