import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import { apiRequest } from '~/apiService'

export default function useHoldingBackofficeChatCenterData({
  customersFilter,
  handleProfile,
}) {
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(6)
  const [dashboards, setDashboards] = useState([])

  const params = {
    branchs: customersFilter?.branchs
      ? customersFilter.branchs.map(x => x.value)
      : [],
    start: customersFilter?.start ?? undefined,
    end: customersFilter?.end ?? undefined,
    requestFromAgency: handleProfile,
    agentId: customersFilter?.consultant?.value,
  }

  useEffect(() => {
    getData()
  }, [customersFilter])

  async function getData() {
    try {
      setLoading(true)

      const [dashboards] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/chatcenter/dashboard', params),
      ])

      setDashboards(dashboards?.value?.data)
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
      setLoading(false)
      setDashboards(dashboardsPlaceholders)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
  }
}
