export const inputs = ddiOptions => [
  {
    empty: true,
    text: '<h5>Dados Pessoais<h5>',
    sm: 12,
  },
  {
    type: 'text',
    name: 'fullName',
    label: 'Nome Completo (conforme documento oficial)',
    max: 40,
    required: true,
    sm: 12,
    md: 12,
    lg: 4,
    xl: 4,
  },
  {
    type: 'select',
    name: 'ddi',
    label: 'DDI',
    options: ddiOptions,
    placeholder: 'Selecione...',
    required: true,
    sm: 12,
    md: 12,
    lg: 2,
    xl: 2,
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Telefone',
    mask: '(99) 99999-9999',
    required: true,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'email',
    name: 'email',
    label: 'E-mail',
    required: true,
    sm: 12,
    md: 12,
    lg: 4,
    xl: 4,
  },
  {
    type: 'date',
    name: 'birthday',
    label: 'Data de Nascimento',
    sm: 12,
    md: 12,
    lg: 2,
    xl: 2,
  },
  {
    type: 'text',
    name: 'cpfCnpj',
    label: 'CPF',
    sm: 12,
    md: 12,
    lg: 2,
    xl: 2,
  },
  {
    type: 'text',
    name: 'nacionality',
    label: 'Nacionalidade',
    max: 20,
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  },
  {
    empty: true,
    text: '<h5>Endereço<h5>',
    sm: 12,
  },
  {
    type: 'text',
    name: 'zipCode',
    label: 'CEP',
    mask: '99.999.-999',
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  },
  {
    type: 'text',
    name: 'address',
    label: 'Endereço',
    max: 40,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6,
  },
  {
    type: 'text',
    name: 'n',
    label: 'N°',
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
  {
    type: 'text',
    name: 'compl',
    label: 'Compl.',
    max: 20,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'text',
    name: 'neighborhood',
    label: 'Bairro',
    max: 30,
    sm: 5,
    md: 5,
    lg: 5,
    xl: 5,
  },
  {
    type: 'text',
    name: 'city',
    label: 'Município',
    max: 30,
    sm: 5,
    md: 5,
    lg: 5,
    xl: 5,
  },
  {
    type: 'text',
    name: 'uf',
    label: 'UF',
    max: 20,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    empty: true,
    text: '<h5>Documentos</h5>',
    sm: 12,
  },
  {
    type: 'text',
    name: 'visaNumber',
    label: 'Número do visto',
    sm: 12,
    lg: 4,
  },
  {
    type: 'text',
    name: 'visaType',
    label: 'Tipo do visto',
    sm: 12,
    lg: 2,
  },
  {
    type: 'date',
    name: 'visaIssueDate',
    label: 'Emissão do visto',
    required: false,
    sm: 12,
    lg: 2,
  },
  {
    type: 'date',
    name: 'visaExpirationDate',
    label: 'Validade do visto',
    required: false,
    sm: 12,
    lg: 2,
  },
  {
    type: 'text',
    name: 'passportNumber',
    label: 'Número do passaporte',
    sm: 12,
    lg: 4,
  },
  {
    type: 'date',
    name: 'passportIssueDate',
    label: 'Emissão do passaporte',
    required: false,
    sm: 12,
    lg: 2,
  },
  {
    type: 'date',
    name: 'passportExpirationDate',
    label: 'Validade do passaporte',
    required: false,
    sm: 12,
    lg: 2,
  },
  {
    empty: true,
    text: '<h5>Outras informações</h5>',
    sm: 12,
  },
  {
    type: 'text',
    name: 'company',
    label: 'Nome da empresa para qual trabalha:',
    max: 50,
    sm: 12,
    md: 12,
    lg: 4,
    xl: 4,
  },
  {
    type: 'text',
    name: 'commercialPhone',
    label: 'Telefone Comercial',
    mask: '(99) 99999-9999',
    sm: 12,
    md: 12,
    lg: 2,
    xl: 2,
  },
  {
    type: 'image',
    name: 'avatar',
    label: 'Foto',
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  {
    type: 'check',
    name: 'confirmTermOfService',
    label: (
      <>
        Li e concordo com os termos e condições dispostos
        <a
          href='https://tripmee.com.br/lgpd.html#politica-privacidade'
          target='_black'
        >
          {' '}
          aqui.
        </a>
      </>
    ),
    required: true,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
]
