import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { rules } from '~/constants/rules'

export default function usePermissions() {
  const auth = useSelector(state => state.auth)
  const user = auth.user

  const [permissions, setPermissions] = useState({
    isMaster: false,
    isAdmin: false,
    isAgent: false,
    viewAgencies: false,
    manageAgencies: false,
    viewGlobalNotification: false,
    manageGlobalNotification: false,
    viewAgents: false,
    manageAgents: false,
    viewHome: false,
    manageHome: false,
    viewTravelers: false,
    manageTravelers: false,
    viewTravels: false,
    manageTravels: false,
    viewExperiences: false,
    manageExperiences: false,
    viewDestinys: false,
    manageDestinys: false,
    viewGeneralNotifications: false,
    manageGeneralNotifications: false,
    viewNotifications: false,
    manageNotifications: false,
    viewRelationship: false,
    manageRelationship: false,
    viewStrategy: false,
    manageStrategy: false,
    viewRefund: false,
    manageRefund: false,
    viewSchool: false,
    manageSchool: false,
    viewEvent: false,
    manageEvent: false,
    viewPartner: false,
    managePartner: false,
    viewLibrary: false,
    manageLibrary: false,
    viewAfterSales: false,
    manageAfterSales: false,
    viewSales: false,
    manageSales: false,
    viewRadar: false,
    manageRadar: false,
    viewSalesReleasesWintour: false,
    viewBackofficeBranchs: false,
    manageBackofficeBranchs: false,
    role: '',
  })

  useEffect(() => {
    let cancel = false

    async function load() {
      if (cancel) return

      if (user) {
        setPermissions({
          ...permissions,
          isMaster: rules.isMaster.some(x => x === user.role),
          isAdmin: rules.isAdmin.some(x => x === user.role),
          isAgent: rules.isAgent.some(x => x === user.role),
          viewAgencies: rules.agencies.view.some(x => x === user.role),
          manageAgencies: rules.agencies.manage.some(x => x === user.role),
          viewAgents: rules.agents.view.some(x => x === user.role),
          manageAgents: rules.agents.manage.some(x => x === user.role),
          viewHome: rules.home.view.some(x => x === user.role),
          manageHome: rules.home.manage.some(x => x === user.role),
          viewTravelers: rules.travelers.view.some(x => x === user.role),
          manageTravelers: rules.travelers.manage.some(x => x === user.role),
          viewTravels: rules.travels.view.some(x => x === user.role),
          manageTravels: rules.travels.manage.some(x => x === user.role),
          viewExperiences: rules.experiences.view.some(x => x === user.role),
          manageExperiences: rules.experiences.manage.some(
            x => x === user.role,
          ),
          viewDestinys: rules.destinys.view.some(x => x === user.role),
          manageDestinys: rules.destinys.manage.some(x => x === user.role),
          viewGeneralNotifications: rules.notifications.view.some(
            x => x === user.role,
          ),
          manageGeneralNotifications: rules.notifications.manage.some(
            x => x === user.role,
          ),
          viewGlobalNotifications: rules.globalnotification.view.some(
            x => x === user.role,
          ),
          manageGlobalNotifications: rules.globalnotification.manage.some(
            x => x === user.role,
          ),
          viewNotifications: rules.notificationstravel.view.some(
            x => x === user.role,
          ),
          manageNotifications: rules.notificationstravel.manage.some(
            x => x === user.role,
          ),
          viewRelationship: rules.relationship.view.some(x => x === user.role),
          manageRelationship: rules.relationship.manage.some(
            x => x === user.role,
          ),
          viewStrategy: rules.strategy.view.some(x => x === user.role),
          manageStrategy: rules.strategy.manage.some(x => x === user.role),
          viewRefund: rules.refund.view.some(x => x === user.role),
          manageRefund: rules.refund.manage.some(x => x === user.role),
          viewSchool: rules.refund.view.some(x => x === user.role),
          manageSchool: rules.refund.manage.some(x => x === user.role),
          viewEvent: rules.pedagogicalEvent.view.some(x => x === user.role),
          manageEvent: rules.pedagogicalEvent.manage.some(x => x === user.role),

          viewPartner: rules.partner.view.some(x => x === user.role),
          managePartner: rules.partner.manage.some(x => x === user.role),

          viewLibrary: rules.libraryManager.view.some(x => x === user.role),
          manageLibrary: rules.libraryManager.manage.some(x => x === user.role),

          viewAfterSales: rules.libraryManager.view.some(x => x === user.role),
          manageAfterSales: rules.libraryManager.manage.some(
            x => x === user.role,
          ),

          viewSales: rules.sales.view.some(
            x => x === user.role && user.viewSales,
          ),
          manageSales: rules.sales.manage.some(x => x === user.role),

          viewRadar: rules.radar.view.some(x => x === user.role),
          manageRadar: rules.radar.manage.some(x => x === user.role),

          viewBackofficeBranchs: rules.backofficeBranchs.view.some(
            x => x === user.role,
          ),
          manageBackofficeBranchs: rules.backofficeBranchs.view.some(
            x => x === user.role,
          ),

          role: user.role,
        })
      }
    }

    load()

    return () => {
      cancel = true
    }
  }, [user])

  return permissions
}
