import { toast } from 'react-toastify'

const toastWrapper = async ({
  error,
  onSuccessMessage,
  onErrorMessage,
  // onNotFoundMessage,
  // onBadRequestMessage,
}) => {
  if (!error) {
    return toast.success(onSuccessMessage)
  }

  if (error && onErrorMessage) {
    return toast.info(onErrorMessage)
  }

  // if (error.response?.status === 404) {
  //   return toast.info(onNotFoundMessage ?? error.response?.data)
  // }

  // if (error.response?.status === 400) {
  //   return toast.info(onBadRequestMessage ?? error.response?.data)
  // }

  // if (error.response?.status === 500) {
  //   return toast.error(onErrorMessage ?? error.response?.data)
  // }

  return toast.info(
    'Um erro inesperado ocorreu, por favor entrar em contato com o suporte.',
  )
}

export { toastWrapper }
