import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'

export const getSource = async ({ token, setLaoding, setData }) => {
  const response = await apiRequest(
    'get',
    `/customerForm/${token}`,
    {},
    setLaoding,
  )

  if (response.error == false) setData(response.data)
}

export const getOptionsDDI = async ({ setDdi }) => {
  const response = await apiRequest('options', '/ddi')

  if (response.error == false) setDdi(response.data)
}

export const requestApiPostToCreate = async ({ data, token }) => {
  if (
    data.confirmTermOfService == undefined ||
    data.confirmTermOfService == false
  ) {
    toast.info(
      'Você precisar confirmar que leu e que está de acordo com as condições gerais.',
    )

    return
  }

  const body = {
    ...data,
    token: token,
  }

  const response = await apiRequest(
    'post',
    '/customer/insert-new-customer-from-short-form',
    body,
  )

  if (response.error == false) {
    toast.success('Cadastro realizado com sucesso!')
    setTimeout(() => window.location.reload(), 1000)
  }
}
