import { useEffect, useState } from 'react'
import usePermissions from './usePermissions'
import { rules } from '~/constants/rules'
import { useSelector } from 'react-redux'
// import { GetAxios } from '~/services/api'

export default function useMenus() {
  const auth = useSelector(state => state.auth)
  // const { axiosApis } = GetAxios()
  const [menus, setMenus] = useState([])
  const permissions = usePermissions()

  useEffect(() => {
    updateMenus()
  }, [permissions])

  async function updateMenus() {
    if (permissions) {
      let newMenus = []

      if (permissions.isMaster) {
        newMenus.push(rules.agencies.menu)

        newMenus.push(rules.agents.menu)

        newMenus.push(rules.city.menu)

        newMenus.push(rules.globalnotification.menu)

        newMenus.push(rules.IntegrationRequest.menu)

        newMenus.push(rules.backofficeBranchs.menu)
      }

      if (permissions.viewHome) {
        newMenus.push(rules.home.menu)
      }

      if (permissions.viewRadar) {
        newMenus.push(rules.radar.menu)
      }

      if (permissions.viewRelationship) {
        if (auth.user.crmIncluded) {
          const strategymenu = rules.relationship.menu
          const submenus = []

          strategymenu.menuSubMenu.map(e => {
            const verification = e.subMenu.view.some(
              x => x === permissions.role,
            )
            if (verification) {
              submenus.push(e)
            }
          })

          newMenus.push({
            ...strategymenu,
            menuSubMenu: submenus,
          })
        }
      }

      if (auth.user.agencyName == 'Wee Travel') {
        newMenus.push(rules.lancamentos.menu)
      }

      newMenus.push(rules.proposal.menu)

      if (permissions.viewTravelers) {
        newMenus.push(rules.travelers.menu)
      }

      if (permissions.viewDestinys && auth.user.role != 'library') {
        const travelManagerMenus = rules.travelManager.menu
        const submenus = []
        travelManagerMenus.menuSubMenu.map(e => {
          let canView = true
          if (e.subMenu.excludeAgencys) {
            e.subMenu.excludeAgencys.some(x => x === auth.user.agencyCode)
          }
          if (canView) {
            submenus.push(e)
          }
        })
        newMenus.push({
          ...travelManagerMenus,
          menuSubMenu: submenus,
        })
      }

      // try {
      //   var res = await axiosApis.get(
      //     `${process.env.REACT_APP_ENDPOINT_APIS}/sales/allow-sales`,
      //   )
      //
      //   if (res.data) {
      //     newMenus.push(rules.sales.menu)
      //   }
      // } catch (error) {
      //   console.log(error)
      // }

      if (permissions.viewSales) {
        const salesMenu = rules.sales.menu

        const submenus = []

        salesMenu.menuSubMenu.map(e => {
          const verification = e.subMenu.view.some(x => x === permissions.role)

          if (e.subMenu.path === '/sales-releases') {
            if (permissions.viewSalesReleasesWintour) {
              if (verification) {
                submenus.push(e)
              }
            }
          } else {
            if (verification) {
              submenus.push(e)
            }
          }
        })

        newMenus.push({
          ...salesMenu,
          menuSubMenu: submenus,
        })
      }

      if (permissions.viewAfterSales) {
        newMenus.push(rules.afterSales.menu)
      }

      if (permissions.viewEvent) {
        newMenus.push(rules.pedagogicalEvent.menu)
      }

      if (permissions.viewLibrary) {
        newMenus.push(rules.libraryManager.menu)
      }

      if (permissions.viewStrategy) {
        const strategymenu = rules.strategy.menu
        const submenus = []

        strategymenu.menuSubMenu.map(e => {
          const verification = e.subMenu.view.some(x => x === permissions.role)
          if (verification) {
            submenus.push(e)
          }
        })

        newMenus.push({
          ...strategymenu,
          menuSubMenu: submenus,
        })
      }

      setMenus(newMenus)
    }
  }

  return { menus, updateMenus }
}
