import { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
import { getDateFormated, getStartEndLast6Month } from '~/functions/Date'
import useAxios from '~/hooks/useAxios'

const DEFAULT_DATES = getStartEndLast6Month()

const DEFAULT_FILTERS = {
  branchs: [],
  start: getDateFormated(DEFAULT_DATES.start, 'YYYY-MM-DD'),
  end: getDateFormated(DEFAULT_DATES.end, 'YYYY-MM-DD'),
  consultant: null,
}

export default function useHoldingBackofficeData() {
  const { apiClient } = useAxios()
  const [loading, setLoading] = useState(false)
  const [holding, setHolding] = useState()
  const [branchs, setBranchs] = useState([])
  const [branchsOptions, setBranchsOptions] = useState([])
  const [consultantOptions, setConsultantOptions] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  useEffect(() => {
    async function getSource() {
      try {
        setLoading(true)

        const agent = await apiClient.options(`/agent`)
        setConsultantOptions(agent.data)

        const resHolding = await apiClient.get('/holdingbackoffice')
        setHolding(resHolding.data)

        const resBranchs = await apiClient.get('/holdingbackoffice/branchs')

        setBranchs(resBranchs.data.items)

        const resBranchsOptions = await apiClient.options(
          '/holdingbackoffice/branchs',
        )
        setBranchsOptions(resBranchsOptions.data.items)
      } catch (error) {
        // handleProfile && toast.info('Holding não encontrada.')
      } finally {
        setLoading(false)
      }
    }

    getSource()

    return () => {
      setLoading(false)
      setHolding(undefined)
      setBranchs(undefined)
      setBranchsOptions(undefined)
    }
  }, [])

  async function changeFilters(data) {
    setFilters(state => ({
      ...state,
      ...data,
    }))
  }

  async function resetFilters() {
    setFilters(DEFAULT_FILTERS)
  }

  return {
    loading,
    holding,
    branchs,
    branchsOptions,
    consultantOptions,
    filters,
    changeFilters,
    resetFilters,
  }
}
