import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import { getBase64 } from '~/functions/File'
import { GetAxios } from '~/services/api'

export default function Edit({ onClose, onRemove, id, reloadList }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState()
  const [defaultValue, setDefaultValue] = useState()
  const [showTime, setShowTime] = useState(false)
  const toEdit = useRef()
  const [previewData, setPreviewData] = useState({
    desc: 'Aqui ficará a descrição',
    startAddress: '',
    image: '',
    links: '[]',
    experiences: '[]',
  })

  useEffect(() => {
    async function loadSource() {
      try {
        setLoading(true)

        let { data } = await axiosApis.get(
          `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/activity/${id}`,
        )

        toEdit.current = data.content
        data = data.content

        console.log(data)
        setShowTime(data.turn === null ? true : false)

        setDefaultValue({
          ...data,
          experiences: data.experiences,
          turn: {
            label: data.turn == null ? 'Selecione um horário' : data.turn,
            value: data.turn == null ? null : data.turn,
          },
          pickUp:
            getDateFormated(data.pickUp, 'HH:mm') === '00:00'
              ? null
              : getDateFormated(data.pickUp, 'HH:mm'),
          start: getDateFormated(data.start, 'HH:mm'),
          end: getDateFormated(data.end, 'HH:mm'),
          date: getDateFormated(data.date, 'YYYY-MM-DD'),
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)

        console.log(error)
      }
    }

    loadSource()
  }, [])

  async function onValidate(data) {
    if (!data.desc) {
      toast.info('Adicione uma descrição.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      if (data.date?.includes('inv')) data.date = null
      if (data.end?.includes('inv')) data.end = null
      if (data.pickUp?.includes('inv')) data.pickUp = null
      if (data.start?.includes('inv')) data.start = null

      let dateStart = data.start ?? ''

      let dateEnd = data.end ?? ''

      console.log(data.date, data.end, data.pickUp, data.start)
      if (data.turn) {
        if (data.turn.value == 'manha') {
          dateStart = '08:30'
          dateEnd = '11:59'
        } else if (data.turn.value == 'tarde') {
          dateStart = '12:00'
          dateEnd = '17:59'
        } else if (data.turn.value == 'noite') {
          dateStart = '18:00'
          dateEnd = '23:59'
        } else if (data.turn.value == 'day') {
          dateStart = '08:30'
          dateEnd = '23:59'
        }
      }

      if ((data.date || data.end || data.pickUp) && !data.date) {
        toast.info(
          'A data é obrigatória caso algum campo de hora esteja preenchido',
        )

        return null
      }

      if (!data.pickUp && data.date) {
        toast.info(
          'O campo PickUp é obrigatório, pois o campo data foi preenchido.',
        )

        return null
      }
      const formData = new FormData()

      formData.append('id', data.id)

      formData.append('title', data.title)

      formData.append('desc', data.desc)

      if (data.turn.value != null) {
        formData.append('turn', data.turn?.label)
      }

      if (data.date)
        if (data.pickUp != '00:00' && data.pickUp != null) {
          formData.append(
            'pickup',
            `${getDateFormated(data.date, 'YYYY-MM-DD')} ${data.pickUp}`,
          )
        }

      if (data.links) {
        formData.append('links', data.links ?? '[]')
      }

      if (data.image) {
        formData.append('image', data.image)
      }

      if (data.experiences) {
        data.experiences.map(e => {
          formData.append('experiences', e)
        })
      }

      if (data.startAddress) {
        formData.append('startAddress', data.startAddress)
      }

      if (data.date && dateStart)
        formData.append(
          'start',
          `${getDateFormated(data.date, 'YYYY-MM-DD')} ${dateStart}`,
        )

      if (data.date && dateEnd)
        formData.append(
          'end',
          `${getDateFormated(data.date, 'YYYY-MM-DD')} ${dateEnd}`,
        )

      let result = await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/activity/${data.id}`,
        formData,
      )

      reloadList('proposalActivity', result)
      toast.success('Atividade editada com sucesso!')

      return onClose(true)
    } catch (error) {
      console.log(error)

      return toast.info('Não foi possível editar a atividade.')
    } finally {
      setLoading(false)
    }
  }

  async function handlePreviewData(e) {
    if (e.target.name === 'image') {
      if (e.target.value) {
        return getBase64(e.target.value, imageBase64 => {
          setPreviewData({
            ...previewData,
            [e.target.name]: imageBase64,
          })
        })
      } else {
        return setPreviewData({
          ...previewData,
          [e.target.name]: '',
        })
      }
    }
    if (e.target.name === 'desc') {
      return setPreviewData({
        ...previewData,
        [e.target.name]:
          e.target.value != '' ? e.target.value : 'Aqui ficará a descrição',
      })
    }

    if (e.target.name === 'links') {
      return setPreviewData({
        ...previewData,
        [e.target.name]: e.target.value ?? '[]',
      })
    }
  }

  async function onDeleteActivity() {
    if (!confirm('Deseja excluir a atividade?')) return

    setLoading(true)
    await onRemove('activity', id, 'proposalActivity')

    await onClose(true)

    setLoading(false)
  }

  return (
    <Container>
      <DefaultForm
        onValidate={onValidate}
        callbackSubmit={onSubmit}
        callbackDelete={onDeleteActivity}
        loading={loading}
        defaultValue={defaultValue}
        inputs={[
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
            onChange: handlePreviewData,
          },
          {
            type: 'image',
            name: 'image',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
            onChange: handlePreviewData,
          },
          {
            type: 'date',
            name: 'date',
            label: 'Data',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'select',
            name: 'turn',
            label: 'Horário',
            placeholder: 'Selecione...',
            options: [
              { value: 'manha', label: 'Manhã', show: false },
              { value: 'tarde', label: 'Tarde', show: false },
              { value: 'noite', label: 'Noite', show: false },
              { value: 'dia', label: 'Dia todo', show: false },
              { value: null, label: 'Personalizado', show: true },
            ],
            onChange: e => setShowTime(e.target.value.show),
            sm: 12,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'pickUp',
            label: 'PickUp',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            sm: 6,
            md: 12,
            lg: 6,
            xl: 6,
            maxLength: 200,
          },
          showTime
            ? {
                type: 'time',
                name: 'start',
                label: 'Começa',
                sm: 6,
                md: 6,
                lg: 3,
                xl: 3,
              }
            : { hide: true },
          showTime
            ? {
                type: 'time',
                name: 'end',
                label: 'Termina',
                sm: 6,
                md: 6,
                lg: 3,
                xl: 3,
              }
            : { hide: true },
          {
            type: 'keyvalue',
            name: 'links',
            label: 'Links úteis',
            placeholder: 'Descrição',
            subPlaceholder2: 'Link',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
            onChange: handlePreviewData,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
