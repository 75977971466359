export const inputs = () => [
  {
    type: 'text',
    name: 'name',
    label: 'Nome',
    required: true,
    sm: 12,
    lg: 4,
  },
  {
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Descrição',
    required: true,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
  },
  {
    type: 'image',
    name: 'photo',
    label: 'Foto',
    required: true,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
  },
]
