/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import { GetAxios } from '~/services/api'
import DaybydaySuggestionsAI from '~/components/AI/DaybydaySuggestionsAI'
import { useLocation, useOutletContext } from 'react-router-dom'

export default function Create({ onClose, reloadList }) {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [experience, setExperience] = useState()
  const [startFromTurn, setStartFromTurn] = useState(undefined)
  const [endFromTurn, setEndFromTurn] = useState(undefined)
  const [reset, setReset] = useState(false)
  const location = useLocation()

  const proposalId = location.state?.id
  const defineValueStartEndFromTurn = value => {
    if (value == 'manha') {
      setStartFromTurn('08:30')
      setEndFromTurn('11:59')
    } else if (value == 'tarde') {
      setStartFromTurn('12:00')
      setEndFromTurn('17:59')
    } else if (value == 'noite') {
      setStartFromTurn('18:00')
      setEndFromTurn('23:59')
    } else if (value == 'dia') {
      setStartFromTurn('08:30')
      setEndFromTurn('23:59')
    }
  }

  async function onValidate(data) {
    if (!data.desc) {
      toast.info('Adicione uma descrição.')
      return
    }

    return true
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      if ((data.date || data.end || data.pickUp) && !data.date) {
        toast.info(
          'A data é obrigatória caso algum campo de hora esteja preenchido.',
        )

        return null
      }

      if (!data.pickUp && data.date) {
        toast.info(
          'O campo PickUp é obrigatório, pois o campo data foi preenchido.',
        )

        return null
      }
      
      const formData = new FormData()

      if (data.title) formData.append('title', data.title)

      if (data.desc) formData.append('desc', data.desc)

      if (data.date && data.pickUp) {
        formData.append(
          'pickup',
          `${getDateFormated(data.date, 'YYYY-MM-DD')} ${data.pickUp}`,
        )
      }

      formData.append('links', data.links ? data.links : '[]')

      let dateStart = startFromTurn == undefined ? data.start : startFromTurn
      if (data.date && dateStart)
      formData.append(
        'start',
        `${getDateFormated(data.date, 'YYYY-MM-DD')} ${dateStart
        }`,
      )

      let dateEnd = endFromTurn == undefined ? data.end : endFromTurn
      if (data.date && dateEnd)
      formData.append(
        'end',
        `${getDateFormated(data.date, 'YYYY-MM-DD')} ${dateEnd
        }`,
      )

      if (data.image) {
        formData.append('image', data.image)
      }

      if (data.experiences) {
        data.experiences.map(e => {
          formData.append('experiences', e)
        })
      }

      if (data.startAddress) formData.append('startAddress', data.startAddress)

      if (data.turn && data.turn?.label != 'Personalizado') {
        formData.append('turn', data.turn.label)
      }

      let result = await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/activity/${proposalId}`,
        formData,
      )

      reloadList('proposalActivity', result)
      toast.success('Atividade criada com sucesso!')
      onClose(true)
      return
    } catch (error) {
      console.log(error)
      toast.info('Não foi possível criar a atividade.')
      return    
    } finally {
      setLoading(false)
    }
  }

  async function OnSelectExperience(e) {
    try {
      setReset(state => !state)

      setExperience({
        startAddress: '',
        desc: '',
        image: null,
        links: '[]',
      })

      if (e.target.value.length == 0) {
        setLoading(true)

        setTimeout(() => {
          setLoading(false)
        }, 500)

        return
      }

      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelexperience/getexperience/${e.target.value[0]}`,
      )

      setExperience({
        desc: res.data.desc,
        image: res.data.image,
        startAddress: res.data.address == null ? ' ' : res.data.address,
        links: '[]',
      })

      return setLoading(false)
    } catch (error) {
      return setLoading(false)
    }
  }

  return (
    <Container>
      {/* <DaybydaySuggestionsAI travelId={travel.id} onClickItem={onClickItem} reset={reset} image /> */}

      <DefaultForm
        onValidate={onValidate}
        callbackSubmit={onSubmit}
        defaultValue={experience}
        loading={loading}
        disabled={loading}
        inputs={[
          {
            type: 'experiences-proposal',
            name: 'experiences',
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
            onChange: OnSelectExperience,
          },
          {
            type: 'html',
            name: 'desc',
            label: 'Descrição',
            required: true,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            type: 'image',
            name: 'image',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
          {
            type: 'date',
            name: 'date',
            label: 'Data',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'select',
            name: 'turn',
            label: 'Horário',
            placeholder: 'Selecione...',
            options: [
              { value: 'manha', label: 'Manhã', show: false },
              { value: 'tarde', label: 'Tarde', show: false },
              { value: 'noite', label: 'Noite', show: false },
              { value: 'dia', label: 'Dia todo', show: false },
              { value: null, label: 'Personalizado', show: true },
            ],
            onChange: e => {
              // setTurn(e.target.value.value)
              setShowTime(e.target.value.show)
              defineValueStartEndFromTurn(e.target.value.value)
            },
            sm: 12,
            md: 6,
            lg: 2,
            xl: 2,
          },
          {
            type: 'time',
            name: 'pickUp',
            label: 'PickUp',
            sm: 3,
            md: 3,
            lg: 2,
            xl: 2,
          },
          {
            type: 'text',
            name: 'startAddress',
            label: 'Endereço',
            required: false,
            sm: 6,
            md: 12,
            lg: 6,
            xl: 6,
            maxLength: 200,
          },
          showTime
            ? {
              type: 'time',
              name: 'start',
              label: 'Começa',
              // disabled: !turn ? false : true,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
            : { hide: true },
          showTime
            ? {
              type: 'time',
              name: 'end',
              label: 'Termina',
              // disabled: !turn ? false : true,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
            : { hide: true },
          {
            type: 'keyvalue',
            name: 'links',
            label: 'Links úteis',
            placeholder: 'Descrição',
            subPlaceholder2: 'Link',
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
