import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import { FormatValueBRL } from '~/functions/FormatValue'
import useHoldingBackofficeProposalsData from '../../dataHooks/useHoldingBackofficeProposals'
import HeaderInPage from '../../components/HeaderInPage'

export default function HoldingBackofficeProposals({ filters, handleProfile }) {
  const { loading, dashboards, chartData, tableData } =
    useHoldingBackofficeProposalsData({ filters, handleProfile })

  return (
    <Container>
      <HeaderInPage title='Proposta' />

      <Divisor margin='5px' />

      <AnalyticsDashboard data={dashboards} columnSizingPerRow={'33.33%'} />

      <Divisor margin='5px' />

      <AnalyticsContainer title='Resumo de propostas(por status)'>
        <AnalyticsLineChart data={chartData} currency />
      </AnalyticsContainer>

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Propostas aprovadas(por consultor)'
        data={tableData}
        loading={loading}
        columns={[
          {
            Header: 'Consultor',
            accessor: 'issuerName',
            width: 300,
          },
          {
            Header: 'Quantidade',
            accessor: 'count',
            width: 300,
          },
          {
            Header: 'Total',
            accessor: 'ammount',
            width: 150,
            Cell: props => {
              return FormatValueBRL(props.row.original.ammount)
            },
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div``
