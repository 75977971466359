import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import useModal from '~/hooks/useModal'
import { GetAxios } from '~/services/api'
import { DivFull } from '~/styles/divs'

export default function ShowInfo({
  traveler,
  callback = null,
  isFromChat = false,
}) {
  const { axiosApis } = GetAxios()
  const [travelerTags, setTravelerTags] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const { hideModal } = useModal()
  const navigate = useNavigate()

  useEffect(() => {
    getOptionsTag(traveler.id)
  }, [])

  async function getOptionsTag(id) {
    try {
      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags/customer/${id}`,
      )

      setTravelerTags(response.data)
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agency/tags`,
      )
      setTagOptions(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  async function onSubmit(data) {
    try {
      const formData = new FormData()

      const tags = data.tags.reduce((acc, obj) => {
        acc.push(obj.label)

        return acc
      }, [])

      formData.append('tag', tags.join(','))
      formData.append('idTraveler', traveler.id)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/customer/tags`,
        formData,
      )

      toast.success('Tags vinculada com sucesso!')
      hideModal()
      if (isFromChat) {
        callback()

        return
      }
      navigate('/home')
      navigate('/relationship-customers')
    } catch (error) {
      if (error && error.response && error.response.data) {
        return toast.info(error.response.data)
      }

      return toast.info('Não foi possível criar tags!')
    }
  }

  return (
    <DivFull style={{ height: '40vh' }}>
      <DefaultForm
        callbackSubmit={onSubmit}
        defaultValue={{
          tags: travelerTags,
        }}
        inputs={[
          {
            type: 'select',
            name: 'tags',
            label: 'Tag',
            placeholder: 'Pesquise por uma tag...',
            options: tagOptions,
            required: true,
            isMulti: true,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
          },
        ]}
      />
    </DivFull>
  )
}
