import ModalDefault from '~/components/ModalDefault'
import DefaultForm from '~/components/DefaultForm'
import { apiRequest } from '~/apiService'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function Modal({
  title = '',
  origin = '',
  id = 0,
  proposalId = 0,
  inputs = [],
  handle = () => {},
  content = '',
  setData,
}) {
  const [loading, setLoading] = useState(false)
  const [defaultValue, setDefaultValue] = useState({})
  const [operation] = useState(id == 0 ? 'post' : 'put')

  useEffect(() => {
    if (operation == 'put') get()
  }, [])

  const get = async () => {
    const result = await apiRequest(
      'get',
      `proposal/${origin}/${id}`,
      {},
      setLoading,
    )

    if (origin == 'destiny') {
      result.data.content.name = {
        label: result.data.content.name,
        value: '',
      }
    }
    setDefaultValue(result?.data?.content)

    result.error && toast.info(result?.erroMessage)
  }

  const onSubmit = async data => {
    if (origin == 'activity') {
      if ((data.date && !data.start) || (!data.date && data.start)) {
        if (data.date) {
          toast.info('Ao preencher a data, é necessário informar o horario.')
          return
        }
        if (data.start) {
          toast.info('Ao preencher o horario, é necessário informar a data.')
          return
        }
      }
    }

    if (origin == 'destiny') {
      data.name = data.name.label
    }

    const result = await apiRequest(
      operation,
      `proposal/${origin}`,
      { ...data, proposalId: proposalId },
      setLoading,
    )

    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })

    handle()

    !result.error && toast.success(result?.data?.message)

    result.error && toast.info(result?.erroMessage)
  }

  return (
    <>
      <ModalDefault
        title={title}
        handleShow={handle}
        content={
          <div>
            <DefaultForm
              callbackSubmit={onSubmit}
              callbackReset={() => {}}
              defaultValue={defaultValue}
              disabled={loading}
              loading={loading}
              inputs={inputs}
            />
          </div>
        }
      />
    </>
  )
}
