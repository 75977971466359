import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import { fileToBase64, getFile } from '~/functions/File'

export const getData = async ({ location, setLoading, setDefaultData }) => {
  const response = await apiRequest(
    'get',
    `product/${location.state.id}`,
    {},
    setLoading,
  )

  setDefaultData({
    ...response.data,
    photo: await getFile(response?.data?.photo?.split(',')[1]),
  })
}

export const onSubmit = async ({ data, setLoading, navigate }) => {
  if (!data.photo) {
    toast.info('É necessário adicionar uma foto para o produto.')
    return
  }

  const body = {
    ...data,
    photo: await fileToBase64(data.photo),
  }
  const response = await apiRequest(
    'put',
    `product`,
    body,
    setLoading,
    'Produto atualizado com sucesso.',
  )

  if (response.error == false) navigate('/library/product')
}
