import { SET_CONTACTS, CLEAR_CONTACT_STATE } from '../actions/contactAction'

const initialState = {
  contacts: [],
  currentServiceContacts: [],
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACTS: {
      return {
        ...state,
        contacts: [...action.data],
      }
    }
    case CLEAR_CONTACT_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default contactReducer
