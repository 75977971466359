import styled from 'styled-components'
import Divisor from '~/components/Divisor'
import IconButton from '~/components/IconButton'
import PageTitle from '~/components/PageTitle'
import { getDateFormated } from '~/functions/Date'
import useModal from '~/hooks/useModal'
import HoldingFilters from '../Filters'

export default function HoldingHeader({
  title,
  filters,
  onFilter,
  onReset,
  branchsOptions,
  handleProfile,
}) {
  const { showModal, hideModal } = useModal()

  async function onSubmitFilters(data) {
    if (data) {
      const newFilters = {
        branchs: data.branchs ?? [],
        start: data.start,
        end: data.end,
      }

      await onFilter(newFilters)
    }

    hideModal()
  }

  async function onResetFilters() {
    await onReset()

    hideModal()
  }

  const showFilters = () => {
    showModal({
      title: 'Filtros',
      body: () => (
        <HoldingFilters
          branchsOptions={branchsOptions}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
          defaultValue={{ ...filters }}
          handleProfile={handleProfile}
        />
      ),
    })
  }

  return (
    <Container>
      <Content title={title}>
        {title ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <i className='fas fa-angle-double-right'></i>
            <PageTitle title={title} head='h6' noButton noMargin />
          </div>
        ) : (
          <span></span>
        )}

        <FiltersContainer>
          {filters &&
            filters.branchs &&
            filters.branchs.map((e, i) => {
              return <FilterBranchTag key={i}>{e.label}</FilterBranchTag>
            })}
          {filters && filters.start && (
            <span>
              <B handleProfile={handleProfile}>Início:</B>&nbsp;{' '}
              {getDateFormated(filters.start, 'l')}
            </span>
          )}
          {filters && filters.end && (
            <span>
              <B handleProfile={handleProfile}>Fim:</B>&nbsp;
              {getDateFormated(filters.end, 'l')}
            </span>
          )}

          <IconButton
            type={'details'}
            onClick={showFilters}
            applyColorTheme={true}
            iconOverride='fas fa-filter'
          />
        </FiltersContainer>
      </Content>

      <Divisor margin='5px' />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: ${props => (props.title ? '1px solid #ccc' : 'none')};
  border-radius: 5px;
  padding: 4px 8px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 16px;
  }
`

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: 920px) {
    justify-content: center;
  }
`

const FilterBranchTag = styled.span`
  color: ${props =>
    props.handleProfile ? props.theme.colors.primary : '#7f2ec2'};
  font-weight: bold;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const B = styled.b`
  color: ${props =>
    props.handleProfile ? props.theme.colors.primary : '#7f2ec2'};
`
