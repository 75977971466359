import IconButton from '~/components/IconButton'
import { onDelete } from './functions'

export const inputsToTable = (navigate, setLoading, setData) => [
  {
    Header: 'Nome',
    accessor: 'label',
    width: 200,
  },
  {
    Header: '',
    accessor: 'detalhes',
    Cell: props => {
      return (
        <IconButton
          id={props.row.index}
          type={'details'}
          onClick={() =>
            navigate('/library/product/details', {
              state: { id: props.row.original.value },
            })
          }
        />
      )
    },
    width: 20,
  },
  {
    Header: '',
    accessor: 'lixeira',
    Cell: props => {
      return (
        <IconButton
          id={props.row.index}
          type={'trash'}
          onClick={() => onDelete({ props, setLoading, setData })}
        />
      )
    },
    width: 20,
  },
]

export const inputsToForm = () => [
  {
    type: 'text',
    name: 'name',
    label: 'Nome',
    sm: 12,
    lg: 4,
  },
]
