import { CLEAR_AGENT_STATE, SET_AGENT_STATE } from '../actions/agentAction'

const initialState = {
  agents: [],
}

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENT_STATE:
      return {
        ...state,
        agents: action.data,
      }
    case CLEAR_AGENT_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default agentReducer
