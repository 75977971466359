import styled from 'styled-components'

export const Container = styled.div`
  width: 85%;
  margin: auto;

  @media screen and (max-width: 920px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`

export const Title = styled.h3`
  text-align: center;
  margin: 32px 0;
`

export const SubTitle = styled.h5`
  margin: 64px 0 32px;
  text-align: center;
`

export const SubTitleM = styled.h5`
  width: 75%;
  text-align: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CoverHeader = styled.div`
  background-color: white;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 85%;
  padding: 24px 0;

  button {
    background-color: transparent;
    color: white;
    font-weight: 700;
    border: transparent;
    border-radius: 5px;
    font-size: 14px;
    padding: 0;
  }

  a {
    background-color: transparent;
    color: white;
    font-weight: 700;
    border: transparent;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    background-color: rgba(213, 74, 38, 0.44);
  }

  .free-trial {
    background-color: #5bc849;
  }

  @media screen and (max-width: 920px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`

export const ImageButton = styled.button`
  background-color: transparent;
  border: transparent;
`

export const Body = styled.div``

export const Footer = styled.div`
  width: 85%;
  margin: 0 auto;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  button {
    background-color: transparent;
    border: transparent;
    font-size: 25px;
    color: #d54a26;
  }

  @media screen and (max-width: 920px) {
    width: 80%;
    padding: 50px 25px 0 0;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 50px 0 0;
  }
`

export const Div = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const Step1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  font-size: 15px;
  font-weight: bold;
  margin: 32px 0;

  div#side-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
  }

  div#iframe-video {
    width: 50%;
  }

  h3 {
    font-size: 22px;
    text-align: justify;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-top: 16px;
      font-weight: 600;

      i {
        color: green;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    flex-direction: column-reverse;

    div#side-text {
      width: 100%;
    }

    div#iframe-video {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    div#side-text {
      width: 100%;
    }

    div#iframe-video {
      width: 100%;
    }
  }
`
