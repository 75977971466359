import styled from 'styled-components'
import { ProgressBar } from 'primereact/progressbar'

export const TheProgressBar = styled(ProgressBar)`
  .p-progressbar-label {
    color: ${props => (props.porcentage < 50 ? '#000' : ' #fff')};
  }

  .p-progressbar-value {
    background: ${props => props.theme.colors.primary};
  }
`
