export const inputs = ({
  leadFormOptions,
  campaignOptions,
  productOptions,
}) => [
  {
    type: 'select',
    name: 'leadForm',
    label: 'Forma de contato',
    options: leadFormOptions,
    placeholder: 'Selecione...',
    required: true,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  },
  {
    type: 'select',
    name: 'campaign',
    label: 'Campanha',
    options: campaignOptions,
    placeholder: 'Selecione...',
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  },
  {
    type: 'select',
    name: 'products',
    label: 'Produto',
    options: productOptions,
    placeholder: 'Selecione...',
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  },
  {
    type: 'check',
    name: 'expanded',
    label: 'Formulário expandido',
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  },
]
