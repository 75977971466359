import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import IconButton from '~/components/IconButton'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import Table from '~/components/Table'
import { GetAxios } from '~/services/api'

export default function BackofficeBranchsList() {
  const { axiosApis } = GetAxios()
  const [data, setData] = useState([])

  useEffect(async () => {
    await onSubmit()

    return () => {
      setData([])
    }
  }, [])

  async function onSubmit(data) {
    try {
      let params = {
        name: data?.name ?? undefined,
        email: data?.email ?? undefined,
        phone: data?.phone ?? undefined,
      }

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/backofficebranch`,
        { params },
      )

      setData(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <PageTitle head='h1' noButton title='Agências com Backoffice' />

      <Panel>
        <PanelHeader title={'Filtros'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            inputs={[
              {
                type: 'text',
                name: 'name',
                label: 'Nome da agência',
              },
              {
                type: 'text',
                name: 'email',
                label: 'Email da agência',
              },
              {
                type: 'text',
                name: 'phone',
                label: 'Telefone da agência',
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da pesquisa'} noButton />

        <PanelBody>
          <Table
            data={data}
            columns={[
              {
                Header: 'Nome da agência',
                accessor: 'name',
                width: 250,
              },
              {
                Header: 'E-mail',
                accessor: 'email',
                width: 250,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 150,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <Link
                      to={'details'}
                      state={{
                        agency: props.row.original,
                      }}
                    >
                      <IconButton
                        id={`details-${props.row.index}`}
                        type={'details'}
                      />
                    </Link>
                  )
                },
                width: 80,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``
