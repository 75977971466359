import {
  CLEAR_CUSTOMER_STATE,
  SET_CUSTOMER_STATE,
} from '../actions/customerAction'

const initialState = {
  customers: [],
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_STATE:
      return {
        ...state,
        customers: action.data,
      }
    case CLEAR_CUSTOMER_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default customerReducer
