import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import { getMoment } from '~/functions/Date'
import { getAlternativePhone, mergeDuplicatedConversations } from '../functions'

PouchDB.plugin(PouchDBFind)

const createPouchDBLocal = ({ databaseName }) => new PouchDB(databaseName)

const createPouchDBConnection = ({ databaseName }) => {
  const databaseNameLowerCase = databaseName.toLowerCase()

  return new PouchDB(
    `${process.env.REACT_APP_CHATCENTER_COUCH_URL}/couch/${databaseNameLowerCase}`,
    {
      auth: {
        username: process.env.REACT_APP_USER_ACCESS_API_CHATCENTER,
        password: process.env.REACT_APP_PASSWORD_ACCESS,
      },
    },
  )
}

const createMessageDatabase = ({ tenant, callback }) => {
  if (!tenant) {
    return callback()
  }

  const databaseName = `message_${tenant}`

  return createPouchDBConnection({ databaseName })
}

const createContactDatabase = ({ tenant, callback }) => {
  if (!tenant) {
    return callback()
  }

  const databaseName = `contact_${tenant}`

  return createPouchDBConnection({ databaseName })
}

const createServiceDatabase = ({ tenant, callback }) => {
  if (!tenant) {
    return callback()
  }

  const databaseName = `service_${tenant}`

  return createPouchDBConnection({ databaseName })
}

const createContactsIndexes = async remoteContactDB => {
  remoteContactDB.createIndex({
    index: {
      fields: ['phone', 'name'],
    },
    name: 'contacts-phone-name-index',
    type: 'json',
  })

  remoteContactDB.createIndex({
    index: {
      fields: ['phone'],
    },
    name: 'contacts-phone-index',
    type: 'json',
  })

  remoteContactDB.createIndex({
    index: {
      fields: ['name'],
    },
    name: 'contacts-name-index',
    type: 'json',
  })
}

const createMessagesIndexes = async remoteMessageDB => {
  remoteMessageDB.createIndex({
    index: {
      fields: ['from', 'to'],
    },
    name: 'conversation-from-to-index',
    type: 'json',
  })

  remoteMessageDB.createIndex({
    index: {
      fields: ['timestamp'],
    },
    name: 'conversation-timestamp-index',
    type: 'json',
  })
}

const getLocalStorageConversations = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const conversationReducer = JSON.parse(root).conversationReducer

    if (conversationReducer) {
      const conversations = JSON.parse(conversationReducer).conversations

      if (conversations) {
        return conversations
      }
    }
  }

  return []
}

const getLocalStorageCurrentConversation = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const currentConversationReducer =
      JSON.parse(root).currentConversationReducer

    if (currentConversationReducer) {
      const currentConversation = JSON.parse(
        currentConversationReducer,
      ).currentConversation

      if (currentConversation) {
        return currentConversation
      }
    }
  }

  return undefined
}

const getLocalStorageMessages = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const messageReducer = JSON.parse(root).messageReducer

    if (messageReducer) {
      const messages = JSON.parse(messageReducer).messages

      if (messages) {
        return messages
      }
    }
  }

  return []
}

const getLocalStorageContacts = async () => {
  const root = localStorage.getItem('persist:tripmee-traveler:root')

  if (root) {
    const contactReducer = JSON.parse(root).contactReducer

    if (contactReducer) {
      const contacts = JSON.parse(contactReducer).contacts

      if (contacts) {
        return contacts
      }
    }
  }

  return []
}

const getConversationsResumed = async ({
  remoteMessageDB,
  agencyPhone,
  agencyId,
}) => {
  // let filterTimestamp = getMoment().add(-1, 'weeks').valueOf()
  // const agencyIdsToLargeData = [53]
  // const messages = agencyIdsToLargeData?.includes(agencyId)
  //   ? await remoteMessageDB.find({
  //       selector: {
  //         _id: {
  //           $gt: null,
  //         },
  //       },
  //       fields: ['_id', 'from', 'to', 'timestamp'],
  //       limit: 50000,
  //       sort: [{ timestamp: 'desc' }],
  //     })
  //   : await remoteMessageDB.find({
  //       // selector: {
  //       //   $and: [
  //       //     {
  //       //       timestamp: {
  //       //         $gt: filterTimestamp,
  //       //       },
  //       //     },
  //       //   ],
  //       // },
  //       // fields: ['_id', 'from', 'to', 'timestamp'],
  //       // limit: 3000,
  //       // sort: [{ timestamp: 'desc' }],
  //       selector: {
  //         _id: {
  //           $gt: null,
  //         },
  //       },
  //       fields: ['_id', 'from', 'to', 'timestamp'],
  //       limit: 10000,
  //       sort: [{ timestamp: 'desc' }],
  //     })

  const messages = await remoteMessageDB.find({
    selector: {
      _id: {
        $gt: null,
      },
    },
    fields: ['_id', 'from', 'to', 'timestamp'],
    limit: 7500,
    sort: [{ timestamp: 'desc' }],
  })

  const messagesSorted = messages.docs.sort((a, b) => b.timestamp - a.timestamp)

  const conversations = []

  messagesSorted.forEach(x => {
    if (
      x.from !== agencyPhone &&
      x.to === 'me' &&
      !conversations.find(y => y.phoneNumber === x.from)
    ) {
      conversations.push({
        ...x,
        phoneNumber: x.from,
      })
    }

    if (
      x.from === agencyPhone &&
      x.to !== 'me' &&
      x.to !== agencyPhone &&
      !conversations.find(y => y.phoneNumber === x.to)
    ) {
      conversations.push({
        ...x,
        phoneNumber: x.to,
      })
    }
  })

  const res = []

  for (let i = 0; i < conversations.length; i++) {
    const conversation = conversations[i]

    const details = await getMessageDetails({
      remoteMessageDB: remoteMessageDB,
      _id: conversation._id,
    })

    if (details) {
      if (details.from !== agencyPhone && details.to === 'me') {
        res.push({
          ...details,
          phoneNumber: details.from,
        })
      }

      if (
        details.from === agencyPhone &&
        details.to !== 'me' &&
        details.to !== agencyPhone
      ) {
        res.push({
          ...details,
          phoneNumber: details.to,
        })
      }
    }
  }

  const merged = mergeDuplicatedConversations({ conversations: res })

  return merged
}

const getContactsResumed = async ({ remoteContactDB }) => {
  const contacts = await remoteContactDB.find({
    selector: {
      $and: [
        {
          $or: [
            {
              name: {
                $gt: null,
              },
            },
            {
              phone: {
                $gt: null,
              },
            },
          ],
        },
      ],
    },
    fields: ['_id', 'name', 'photo', 'phone'],
  })

  return contacts.docs
}

const getConversationsResumedByChange = async ({
  remoteMessageDB,
  agencyPhone,
}) => {
  const conversations = await getLocalStorageConversations()

  const lastMessage = conversations.sort((a, b) => b.timestamp - a.timestamp)[0]

  let lastTimestamp = lastMessage?.timestamp

  if (!lastTimestamp) {
    lastTimestamp = getMoment().add(-1, 'month').valueOf()
  }

  const messagesFromLastTimestamp = await getMessagesByFromTimestamp({
    remoteMessageDB,
    lastTimestamp,
  })

  const messagesFromLastTimestampSorted = messagesFromLastTimestamp.sort(
    (a, b) => b.timestamp - a.timestamp,
  )

  const newConversationsFromLastTimestamp = []

  for (let i = 0; i < messagesFromLastTimestampSorted.length; i++) {
    let message = messagesFromLastTimestampSorted[i]

    if (
      message.from !== agencyPhone &&
      message.to === 'me' &&
      !newConversationsFromLastTimestamp.find(x => x.from === message.from)
    ) {
      newConversationsFromLastTimestamp.push({
        ...message,
        newMessage: true,
        phoneNumber: message.from,
      })
    }

    if (
      message.from === agencyPhone &&
      message.to !== 'me' &&
      message.to !== agencyPhone &&
      !newConversationsFromLastTimestamp.find(x => x.from === message.from)
    ) {
      newConversationsFromLastTimestamp.push({
        ...message,
        newMessage: false,
        phoneNumber: message.to,
      })
    }
  }

  const conversationsFiltered = conversations.filter(x => {
    if (
      !newConversationsFromLastTimestamp.find(
        y => y.phoneNumber === x.phoneNumber,
      )
    ) {
      return true
    }

    return false
  })

  const res = newConversationsFromLastTimestamp
    .concat(conversationsFiltered)
    .sort((a, b) => b.timestamp - a.timestamp)

  return res
}

const getMessages = async ({
  remoteMessageDB,
  agencyPhone,
  currentConversation,
}) => {
  if (!currentConversation || !currentConversation.phoneNumber) {
    return []
  }

  const messages = await getMessagesByFrom({
    remoteMessageDB,
    agencyPhone,
    phoneNumber: currentConversation.phoneNumber,
  })

  return messages
}

const getMessagesByChangeToCurrentConversation = async ({
  remoteMessageDB,
  agencyPhone,
}) => {
  const currentConversation = await getLocalStorageCurrentConversation()

  if (currentConversation) {
    const messages = await getLocalStorageMessages()

    const lastMessage = messages.sort((a, b) => b.timestamp - a.timestamp)[0]

    let lastTimestamp = lastMessage?.timestamp

    if (!lastTimestamp) {
      lastTimestamp = getMoment().add(-1, 'week').valueOf()
    }

    if (lastMessage) {
      const newMessages = await getMessagesByFromTimestampAndFrom({
        remoteMessageDB,
        lastTimestamp: lastTimestamp,
        agencyPhone: agencyPhone,
        from: currentConversation.phoneNumber,
      })

      // Verifica a mensagem já existe para não adicionar de novo
      if (
        newMessages.length > 0 &&
        messages.some(m => m._id == newMessages[0]._id)
      ) {
        return messages
      }

      const res = [...messages, ...newMessages].sort(
        (a, b) => b.timestamp - a.timestamp,
      )

      return res
    }

    return messages.sort((a, b) => b.timestamp - a.timestamp)
  }

  return []
}

const getMessageDetails = async ({ remoteMessageDB, _id }) => {
  const details = await remoteMessageDB.find({
    selector: {
      _id: _id,
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
      'messageId',
      'referencedMessageContent',
      'referencedMessageOwner',
      'image.caption',
      'caption',
      'document.caption',
      'video.caption',
    ],
  })

  if (details.docs.length > 0) {
    return details.docs[0]
  }

  return undefined
}

const getMessagesByFromTimestamp = async ({
  remoteMessageDB,
  lastTimestamp,
}) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          timestamp: {
            $gt: lastTimestamp,
          },
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
      'messageId',
      'referencedMessageContent',
      'referencedMessageOwner',
      'image.caption',
      'caption',
      'document.caption',
      'video.caption',
    ],
    limit: 10000,
  })

  return res.docs
}

const getMessagesByFromTimestampAndFrom = async ({
  remoteMessageDB,
  lastTimestamp,
  agencyPhone,
  from,
}) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          timestamp: {
            $gt: lastTimestamp,
          },
        },
        {
          $or: [
            {
              $and: [
                {
                  from: from,
                },
                {
                  to: 'me',
                },
              ],
            },
            {
              $and: [
                {
                  from: agencyPhone,
                },
                {
                  to: from,
                },
              ],
            },
          ],
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
      'messageId',
      'referencedMessageContent',
      'referencedMessageOwner',
      'image.caption',
      'caption',
      'document.caption',
      'video.caption',
    ],
    limit: 10000,
  })

  return res.docs
}

const getMessagesByFrom = async ({
  agencyPhone,
  remoteMessageDB,
  phoneNumber,
}) => {
  const resToMe = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          from: phoneNumber,
        },
        {
          to: 'me',
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
      'messageId',
      'referencedMessageContent',
      'referencedMessageOwner',
      'image.caption',
      'caption',
      'document.caption',
      'video.caption',
    ],
    limit: 10000,
  })

  const resToOther = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          from: agencyPhone,
        },
        {
          to: phoneNumber,
        },
      ],
    },
    fields: [
      '_id',
      'from',
      'to',
      'sent',
      'read',
      'delivered',
      'type',
      'text.body',
      'timestamp',
      'messageId',
      'referencedMessageContent',
      'referencedMessageOwner',
      'image.caption',
      'caption',
      'document.caption',
      'video.caption',
    ],
    limit: 10000,
  })

  const alternativePhone = getAlternativePhone(phoneNumber)

  const resToMeAlternative = {
    docs: [],
  }

  const resToOtherAlternative = {
    docs: [],
  }

  if (alternativePhone) {
    const alternativeMessagesToMe = await remoteMessageDB.find({
      selector: {
        $and: [
          {
            from: alternativePhone,
          },
          {
            to: 'me',
          },
        ],
      },
      fields: [
        '_id',
        'from',
        'to',
        'sent',
        'read',
        'delivered',
        'type',
        'text.body',
        'timestamp',
        'messageId',
        'referencedMessageContent',
        'referencedMessageOwner',
        'image.caption',
        'caption',
        'document.caption',
        'video.caption',
      ],
      limit: 10000,
    })

    alternativeMessagesToMe.docs.forEach(x => {
      resToMeAlternative.docs.push(x)
    })

    const alternativeMessagesToOther = await remoteMessageDB.find({
      selector: {
        $and: [
          {
            from: agencyPhone,
          },
          {
            to: alternativePhone,
          },
        ],
      },
      fields: [
        '_id',
        'from',
        'to',
        'sent',
        'read',
        'delivered',
        'type',
        'text.body',
        'timestamp',
        'messageId',
        'referencedMessageContent',
        'referencedMessageOwner',
        'image.caption',
        'caption',
        'document.caption',
        'video.caption',
      ],
      limit: 10000,
    })

    alternativeMessagesToOther.docs.forEach(x => {
      resToOtherAlternative.docs.push(x)
    })
  }

  const res = [
    ...resToMe.docs,
    ...resToOther.docs,
    ...resToMeAlternative.docs,
    ...resToOtherAlternative.docs,
  ].sort((a, b) => b.timestamp - a.timestamp)

  return res
}

const getMediaByMessage = async ({ remoteMessageDB, message }) => {
  const res = await remoteMessageDB.find({
    selector: {
      $and: [
        {
          _id: message._id,
        },
      ],
    },
    fields: ['_id', 'media'],
  })

  if (res.docs.length > 0) {
    return res.docs[0]
  }

  return undefined
}

const getContactsByChange = async ({ remoteContactDB, id }) => {
  const contacts = await getLocalStorageContacts()

  if (!contacts.find(x => x._id === id)) {
    const res = await remoteContactDB.find({
      selector: {
        $and: [
          {
            _id: id,
          },
        ],
      },
      fields: ['_id', 'tenant', 'name', 'photo', 'phone'],
    })

    if (res.docs.length > 0) {
      const newContact = res.docs[0]

      return {
        changed: true,
        contacts: [...contacts, newContact],
      }
    }
  }

  return {
    changed: false,
    contacts: contacts,
  }
}

const getNewConversationsByRead = async ({ id }) => {
  const conversations = await getLocalStorageConversations()

  const newConversations = conversations.map(c => {
    if (c._id === id) {
      return {
        ...c,
        read: true,
      }
    }

    return c
  })

  const merged = mergeDuplicatedConversations({
    conversations: newConversations,
  })

  return merged
}

const removeServiceDocumentByPhone = async ({ remoteServiceDB, phone }) => {
  const servicesToRemove = await remoteServiceDB.find({
    selector: {
      phone: phone,
    },
  })

  servicesToRemove.docs.forEach(async doc => {
    await remoteServiceDB.remove(doc)
  })

  const alternativePhone = getAlternativePhone(phone)

  if (alternativePhone) {
    const alternativeServicesToRemove = await remoteServiceDB.find({
      selector: {
        phone: alternativePhone,
      },
    })

    alternativeServicesToRemove.docs.forEach(async doc => {
      await remoteServiceDB.remove(doc)
    })
  }
}

const putServiceDocumentsArray = async ({ remoteServiceDB, documents }) => {
  documents.forEach(async element => {
    await remoteServiceDB.put(element)
  })
}

const getServiceDocumentsByPhone = async ({ remoteServiceDB, phone }) => {
  const servicesDocuments = await remoteServiceDB.find({
    selector: {
      $and: [
        {
          phone: phone,
        },
      ],
    },
  })

  const response = [...servicesDocuments.docs]

  const alternativePhone = getAlternativePhone(phone)

  if (alternativePhone) {
    const servicesDocumentsAlternatives = await remoteServiceDB.find({
      selector: {
        $and: [
          {
            phone: alternativePhone,
          },
        ],
      },
    })

    servicesDocumentsAlternatives.docs.forEach(element => {
      response.push(element)
    })
  }

  return response
}

export {
  createPouchDBLocal,
  createPouchDBConnection,
  createContactsIndexes,
  createMessagesIndexes,
  getConversationsResumed,
  getConversationsResumedByChange,
  getMessages,
  getMessageDetails,
  getMediaByMessage,
  getMessagesByChangeToCurrentConversation,
  getContactsResumed,
  createMessageDatabase,
  createContactDatabase,
  createServiceDatabase,
  getContactsByChange,
  getNewConversationsByRead,
  removeServiceDocumentByPhone,
  putServiceDocumentsArray,
  getServiceDocumentsByPhone,
}
