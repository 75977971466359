import styled from 'styled-components'
import PageTitle from '~/components/PageTitle'
import { SpaceBetweenDiv } from '~/styles/divs'
import { DeleteButton } from '~/components/Button/styles'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'

export default function Modal({ title, onClose, children }) {
  const terte = useOutletContext()

  useEffect(() => {
    console.log(terte)
  }, [])

  return (
    <Container>
      <Content>
        <Title>
          <PageTitle title={title} head={'h6'} noButton />

          <DeleteButton onClick={() => onClose(false)}>
            <i className='fas fa-times' />
          </DeleteButton>
        </Title>

        <div style={{ padding: '8px' }}>{children}</div>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
`

const Content = styled.div`
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 16px #000;
  width: 90%;
  overflow-y: auto;
  max-height: 100%;
  z-index: 1;

  .react-select__menu > div {
    max-height: 140px;
  }
`

const Title = styled(SpaceBetweenDiv)`
  padding: 8px;
  background: #eaeaea;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
