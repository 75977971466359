import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
`
export const ContactsSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #caccce;
  background: #ffffff;
  padding: 0px;
  margin: 0px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d3d3d3;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bdbebd;
  }

  min-width: 464px;
  max-width: 464px;

  @media (max-width: 1280px) {
    max-width: 404px;
    min-width: 404px;
  }
`
export const SectionChat = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex: 2;
  flex-direction: column;
  border-radius: 10px;
  padding: 0px 8px;
  margin: 0px;
  overflow-x: hidden;
`

export const SectionInfo = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #caccce;
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow: auto;
  flex: 1;
  /* max-width: 300px; */
  max-width: 461.5px;

  @media (max-width: 767.5px) {
    display: none;
  }
`
export const ContainerError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 24px;

  button {
    font-size: 18px;
  }
`
// @media screen and (max-width: 1536px) {
//     .wrapper {
//       max-width: 1366px;
//     }
//   }
//   @media screen and (max-width: 1280px) {
//     .wrapper {
//       max-width: 1024px;
//     }
//   }
//   @media screen and (max-width: 1024px) {
//     .wrapper {
//       max-width: 768px;
//       padding-left: 40px;
//       padding-right: 40px;
//     }
//   }
//   @media screen and (max-width: 768px) {
//     .wrapper {
//       max-width: 640px;
//     }
//   }
//   @media screen and (max-width: 640px) {
//     .wrapper {
//       max-width: 475px;
//     }
//   }
