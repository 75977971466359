import { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { apiRequest } from '~/apiService'
import { getMoment } from '~/functions/Date'
import {
  getAgentsFromAgency,
  getClientsDataForContacts,
  getClientsDataForContactsAlternative,
} from '~/services/chat'
import { setAgentState } from '~/store/actions/agentAction'
import { setContacts } from '~/store/actions/contactAction'
import { setConversations } from '~/store/actions/conversationAction'
import { setCurrentConversationAction } from '~/store/actions/currentConversationAction'
import { setCustomerState } from '~/store/actions/customerAction'
import { setAllMessages, setLastMessage } from '~/store/actions/messageAction'
import { applyServiceChanges, setServices } from '~/store/actions/serviceAction'
import { navigateHeaderNavItem } from '~/store/root/slices/page'
import { getAlternativePhone } from '../../functions'
import {
  createContactDatabase,
  createContactsIndexes,
  createMessageDatabase,
  createMessagesIndexes,
  createServiceDatabase,
  getContactsByChange,
  getContactsResumed,
  getConversationsResumed,
  getConversationsResumedByChange,
  getMediaByMessage,
  getMessages,
  getMessageDetails,
  getMessagesByChangeToCurrentConversation,
  getNewConversationsByRead,
  putServiceDocumentsArray,
  removeServiceDocumentByPhone,
  getServiceDocumentsByPhone,
} from '../../services'
import ChatSkeleton from '../../Skeleton'

export const ChatContext = createContext()

export default function ChatContextProvider({ children }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(x => x.auth)
  const conversationsState = useSelector(
    state => state.conversationReducer.conversations,
  )
  const servicesState = useSelector(state => state.serviceReducer.services)
  const lastMessageReceivedState = useSelector(
    state => state.messageReducer.lastMessageReceived,
  )
  const contactsState = useSelector(state => state.contactReducer.contacts)
  const customerState = useSelector(state => state.customerReducer.customers)
  const agentsState = useSelector(state => state.agentReducer.agents)
  const agencyPhone = `${
    auth.user.agencyPhoneDDI ?? ''
  }${auth.user.agencyPhone.replace(/[^0-9]/g, '')}`
  const remoteMessageDB = createMessageDatabase({
    tenant: auth.tenant,
    callback: () => navigate('/home'),
  })
  const remoteContactDB = createContactDatabase({
    tenant: auth.tenant,
    callback: () => navigate('/home'),
  })
  const remoteServiceDB = createServiceDatabase({
    tenant: auth.tenant,
    callback: () => navigate('/home'),
  })
  const [loading, setLoading] = useState(false)
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(null)
  const [transferOrFinalizingText, setTransferOrFinalizingText] = useState()
  const [transferOrFinalizingLoading, setTransferOrFinalizingLoading] =
    useState(false)
  const [currentConversation, setCurrentConversation] = useState()
  const [clickedOnAttend, setClickedOnAttend] = useState([])

  const servicesChangesRef = useRef([])

  let timeoutMessage = undefined
  let timeoutContact = undefined
  let timeoutService = undefined

  useEffect(async () => {
    setLoading(true)

    dispatch(navigateHeaderNavItem('chat'))

    await createMessagesIndexes(remoteMessageDB)

    await createContactsIndexes(remoteMessageDB)

    await updateAll()

    setLoading(false)

    const remoteMessageDBChanges = remoteMessageDB
      .changes({
        since: 'now',
        live: true,
        include_docs: false,
      })
      .on('change', change => {
        clearTimeout(timeoutMessage)

        timeoutMessage = setTimeout(() => {
          callbackMessageChange(change)
        }, 3000)
      })

    const remoteContactDBChanges = remoteContactDB
      .changes({
        since: 'now',
        live: true,
        include_docs: false,
      })
      .on('change', change => {
        clearTimeout(timeoutContact)

        timeoutContact = setTimeout(() => {
          callbackContactChange(change)
        }, 3000)
      })

    const remoteServiceDBChanges = remoteServiceDB
      .changes({
        since: 'now',
        live: true,
        include_docs: true,
      })
      .on('change', change => {
        servicesChangesRef.current = [...servicesChangesRef.current, change]

        clearTimeout(timeoutService)

        timeoutService = setTimeout(() => {
          callbackServiceChange()
        }, 2000)
      })

    return () => {
      clearTimeout(timeoutMessage)
      clearTimeout(timeoutContact)
      remoteMessageDBChanges.cancel()
      remoteContactDBChanges.cancel()
      remoteServiceDBChanges.cancel()
    }
  }, [])

  useEffect(async () => {
    if (lastMessageReceivedState) {
      markFinalizedChatAsUnread(lastMessageReceivedState)
    }
  }, [lastMessageReceivedState])

  async function updateAll() {
    const agencyId = auth.user.agencyId
    const conversations = await getConversationsResumed({
      remoteMessageDB,
      agencyPhone,
      agencyId,
    })

    await updateAgents()

    if (conversations.length > 0) {
      const phoneList = conversations.map(x => x.phoneNumber)

      if (phoneList.length > 0) {
        await updateCustomers({ phoneListOverride: phoneList })
      }
    }

    const contacts = await getContactsResumed({
      remoteContactDB,
    })

    setContacts(contacts, dispatch)

    const response = await remoteServiceDB.allDocs({
      include_docs: true,
    })

    setServices(
      response.rows.map(e => e.doc),
      dispatch,
    )

    setClickedOnAttend([])

    setConversations(conversations, dispatch)
  }

  async function callbackMessageChange(change) {
    if (change?.id) {
      const conversations = await getConversationsResumedByChange({
        auth,
        remoteMessageDB,
        agencyPhone,
      })

      setConversations(conversations, dispatch)

      const newMessages = await getMessagesByChangeToCurrentConversation({
        remoteMessageDB,
        agencyPhone,
      })

      setAllMessages(newMessages, dispatch)

      setLastMessage(change, dispatch)
    }
  }

  async function getMessagesByCurrentConversation(currentConversation) {
    setCurrentConversationAction(currentConversation, dispatch)

    const messages = await getMessages({
      remoteMessageDB,
      agencyPhone,
      currentConversation,
    })

    setAllMessages(messages, dispatch)
  }

  async function clearAllConversationMessages() {
    setAllMessages([], dispatch)
  }

  async function getMedia(message) {
    if (message && message.type !== 'text') {
      const media = await getMediaByMessage({
        remoteMessageDB,
        message,
      })

      if (media) {
        return media
      }
    }

    return undefined
  }

  async function callbackContactChange(change) {
    if (change?.id && !loading) {
      const newContacts = await getContactsByChange({
        remoteContactDB,
        id: change.id,
      })

      if (newContacts.changed) {
        setContacts(newContacts.contacts, dispatch)
      }
    }
  }

  async function readConversation(id) {
    const newConversations = await getNewConversationsByRead({
      id: id,
    })

    setConversations(newConversations, dispatch)
  }

  async function callbackServiceChange() {
    applyServiceChanges(servicesChangesRef.current, dispatch)

    servicesChangesRef.current = []

    setClickedOnAttend([])
  }

  async function removeServiceDocument(phone) {
    await removeServiceDocumentByPhone({
      remoteServiceDB: remoteServiceDB,
      phone: phone,
    })
  }

  async function putServiceDocuments(documents) {
    await putServiceDocumentsArray({
      remoteServiceDB: remoteServiceDB,
      documents: documents,
    })
  }

  async function transferConversation({
    email,
    phone,
    name,
    isTransferingAgent,
    currentConversationToSet,
    startAttend,
  }) {
    try {
      setTransferOrFinalizingLoading(true)

      setClickedOnAttend(state => [...state, phone])

      if (currentConversationToSet || startAttend) {
        setTransferOrFinalizingText('Iniciando atendimento...')

        if (currentConversationToSet) {
          setCurrentConversation(currentConversation)
        }
      }

      if (!currentConversationToSet && !startAttend) {
        setTransferOrFinalizingText('Transferindo conversa...')

        setCurrentConversation(undefined)
      }

      await removeServiceDocument(phone)

      const docsToPut = []

      docsToPut.push({
        _id: getMoment().toJSON(),
        email: email,
        phone: phone,
        name: name,
        new: true,
        status: 'atendimento',
      })

      const alternativePhone = getAlternativePhone(phone)

      if (alternativePhone) {
        docsToPut.push({
          _id: getMoment().add(1, 'second').toJSON(),
          email: email,
          phone: alternativePhone,
          name: name,
          new: true,
          status: 'atendimento',
        })
      }

      await putServiceDocuments(docsToPut)

      if (!isTransferingAgent) {
        let _chatService = {
          phone: phone,
          alternativePhone: alternativePhone,
        }

        await apiRequest(
          'post',
          `CustomerChatCenter/StartChatService/`,
          _chatService,
          setLoading,
        )
      }

      setTransferOrFinalizingLoading(false)
    } catch (error) {
      setTransferOrFinalizingLoading(false)

      console.log(error)
    }
  }

  async function markFinalizedChatAsUnread(change) {
    const doc = await getMessageDetails({
      remoteMessageDB: remoteMessageDB,
      _id: change.id,
    })

    if (doc && doc.to === 'me') {
      const serviceDocuments = await getServiceDocumentsByPhone({
        remoteServiceDB: remoteServiceDB,
        phone: doc.from,
      })

      const serviceDocument = serviceDocuments.find(
        x => x.status === 'finalizado',
      )

      if (serviceDocument) {
        await transferConversation({
          email: serviceDocument.email,
          phone: serviceDocument.phone,
          name: serviceDocument.name,
        })
      }
    }
  }

  async function finalizeConversation({ phone, motivation }) {
    try {
      setClickedOnAttend([])

      setTransferOrFinalizingLoading(true)

      setTransferOrFinalizingText('Finalizando conversa...')

      setCurrentConversation(undefined)

      const serviceDocuments = await getServiceDocumentsByPhone({
        remoteServiceDB: remoteServiceDB,
        phone: phone,
      })

      if (serviceDocuments.length > 0) {
        await removeServiceDocument(phone)
      }

      const serviceDocument = serviceDocuments[0]

      const docsToPut = []

      docsToPut.push({
        _id: getMoment().toJSON(),
        email: serviceDocument.email,
        phone: phone,
        name: serviceDocument.name,
        new: true,
        motivate: motivation,
        status: 'finalizado',
      })

      const alternativePhone = getAlternativePhone(phone)

      if (alternativePhone) {
        docsToPut.push({
          _id: getMoment().add(1, 'second').toJSON(),
          email: serviceDocument.email,
          phone: alternativePhone,
          name: serviceDocument.name,
          new: true,
          status: 'finalizado',
          motivate: motivation,
        })
      }

      await putServiceDocuments(docsToPut)

      let _chatService = {
        phone: phone,
        alternativePhone: alternativePhone,
        CompletionReason: motivation,
      }

      await apiRequest(
        'post',
        `CustomerChatCenter/FinishChatService/`,
        _chatService,
      )

      setTransferOrFinalizingLoading(false)
    } catch (error) {
      setTransferOrFinalizingLoading(false)
    }
  }

  async function updateCustomers({ phoneListOverride = [] }) {
    try {
      let phoneList = []

      if (phoneListOverride.length > 0) {
        phoneList = [...phoneListOverride]
      } else {
        phoneList = [...conversationsState.map(x => x.phoneNumber)]
      }

      const response = await getClientsDataForContacts(phoneList)

      const responseAlternative = await getClientsDataForContactsAlternative(
        phoneList,
      )

      const newClientsData = [...response.data, ...responseAlternative.data]

      setCustomerState(newClientsData, dispatch)
    } catch (error) {
      console.log(error)
    }
  }

  async function updateAgents() {
    try {
      const responseAgents = await getAgentsFromAgency()

      setAgentState(responseAgents.data, dispatch)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ChatContext.Provider
      value={{
        conversationsState,
        servicesState,
        contactsState,
        customerState,
        agentsState,
        getMessagesByCurrentConversation,
        clearAllConversationMessages,
        getMedia,
        readConversation,
        removeServiceDocument,
        putServiceDocuments,
        transferConversation,
        finalizeConversation,
        transferOrFinalizingLoading,
        transferOrFinalizingText,
        currentConversation,
        setCurrentConversation,
        updateCustomers,
        clickedOnAttend,
        setClickedOnAttend,
        currentPhoneNumber,
        setCurrentPhoneNumber,
      }}
    >
      {loading && conversationsState?.length === 0 && <ChatSkeleton />}

      {(!loading || conversationsState?.length > 0) && children}
    </ChatContext.Provider>
  )
}
