import { useState } from 'react'
import * as S from './styles'
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap'

export default function MessageMenu({
  message,
  direction = 'down',
  phone,
  setSelectedMessage,
  ...args
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  function handleReplymessage() {
    setSelectedMessage()
  }

  return (
    <S.DropdownContainer>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <S.DropdownButton caret></S.DropdownButton>
        <DropdownMenu {...args}>
          <DropdownItem key={`reply`} onClick={() => handleReplymessage()}>
            Responder
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </S.DropdownContainer>
  )
}
