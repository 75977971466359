import { useEffect, useState } from 'react'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { onSubmit } from './functions'
import { inputsToForm, inputsToTable } from './inputs'
import { useNavigate } from 'react-router-dom'
import { LinkRegisterNewTraveler } from '~/components/Link/styles'

export default function ProductList() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    onSubmit({ setLoading, setData })
  }, [])

  return (
    <Container>
      <h1 className='page-header'>Lista de produtos</h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={data => onSubmit({ data, setLoading, setData })}
            callbackReset={() => {}}
            submitLabel={'Pesquisar'}
            inputs={inputsToForm()}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <LinkRegisterNewTraveler to='create'>
            Nova produto
          </LinkRegisterNewTraveler>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={inputsToTable(navigate, setLoading, setData)}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
