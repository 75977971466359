import { useContext, useEffect, useState } from 'react'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { toast } from 'react-toastify'
import { GetAxios } from '~/services/api'
import FormContactForm from './FormContactForm'
import DefaultForm from '~/components/DefaultForm'
import { useSelector } from 'react-redux'
import context from '~/context/createContext'
import IconButton from '~/components/IconButton'
import NewKanban from '~/components/NewKanban'
import useModal from '~/hooks/useModal'
import EditListComponent from './EditListComponent'
// import Dashboard from './Dashboard'
import ProposalCreate from '../NewProposal/Create'
import AddListComponent from './AddListComponent'
import { getDateFormated, getMoment } from '~/functions/Date'
import Create from '../Travels/Create'

const FORMAT_DATE = 'YYYY-MM-DD'
const NOW = getMoment()

export default function RelationshipPipeline() {
  const { axiosApis } = GetAxios()
  const { globalFilters, resetGlobalFilters } = useContext(context)
  const [data, setData] = useState([])
  // const [dashboards, setDashboards] = useState([])
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [leadStatusOptions, setLeadStatusOptions] = useState([])
  const [leadFormOptions, setLeadFormOptions] = useState([])
  const [agentOptions, setAgentOptions] = useState([])
  const [motivationOptions, setMotivationOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([
    { label: 'Nenhum', value: '' },
  ])
  const [observationOptions, setObservationOptions] = useState([])
  const [params, setParams] = useState(undefined)
  const auth = useSelector(state => state.auth)
  // const [labelHeader, setLabelHeader] = useState('')
  // const [showDefaultForm, setShowDefaultForm] = useState(false)
  const [pageFilters] = useState({
    dateStart: globalFilters.dateStart,
    dateEnd: globalFilters.dateEnd,
  })
  const { showModal, hideModal } = useModal()

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        setLoading(true)

        await getData()

        const agent = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agent`,
        )

        setAgentOptions(agent.data)

        await getOptions()

        const leadFormResponse = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/customer/leadform`,
        )

        setLeadFormOptions(leadFormResponse.data)

        const motivation = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/motivation`,
        )

        setMotivationOptions(motivation.data)

        const product = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/product`,
        )

        setProductOptions(product.data)

        const campaigns = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/campaign`,
        )

        setCampaignOptions(oldState => [...oldState, ...campaigns.data])

        const observation = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/observation`,
        )

        setObservationOptions(
          observation.data.length > 0
            ? [{ label: 'Selecione...', value: '' }, ...observation.data]
            : observation.data,
        )
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()
    // defaultFormForAdmin()

    return () => {
      cancel = true
      // setDashboards([])
    }
  }, [])

  // const defaultFormForAdmin = () => {
  //   if (auth.user.role == 'admin') {
  //     setLabelHeader('Resultado da Pesquisa')
  //     setShowDefaultForm(true)
  //   }
  // }

  async function getData(e) {
    try {
      setLoading(true)

      // setDashboards([])

      const body = {
        agentId: e?.agent?.value ?? 0,
        prospection: e?.prospection ? true : false,
        customerId: e?.customer?.value ?? 0,
        start: e?.dateStart ? `${e.dateStart} 00:00:00` : undefined,
        end: e?.dateEnd ? `${e.dateEnd} 23:59:59` : undefined,
        campaign: e?.campaign?.value ?? 0,
      }

      // const dashboardResponse = await axiosApis.get(
      //   `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/dashboards`,
      //   { params: body },
      // )

      // setDashboards(dashboardResponse.data)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
        { params: body },
      )

      setData(data)

      await getOptions()

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function getOptions() {
    try {
      setLoading(true)

      const leadStatusResponse = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban`,
      )

      setLeadStatusOptions(leadStatusResponse.data)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  async function callbackOnMove({ item, newStage, oldStage }) {
    try {
      if (newStage.title !== oldStage.title) {
        setLoading(true)

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/move`,
          {
            id: item.id,
            newStatus: newStage.title,
          },
        )

        await getData(params)

        setTimeout(() => setLoading(false), 1000)
      }

      return true
    } catch (error) {
      setTimeout(() => setLoading(false), 1000)

      return false
    }
  }

  async function callbackOnAdd({ item: stage }) {
    showModal({
      title: 'Novo contato',
      desc: `Adicionar em "${stage.title}"`,
      body: () => (
        <FormContactForm
          data={{ stage }}
          productOptions={productOptions}
          motivationOptions={motivationOptions}
          observationOptions={observationOptions}
          agentOptions={agentOptions}
          leadFormOptions={leadFormOptions}
          leadStatusOptions={leadStatusOptions}
          campaignOptions={campaignOptions}
          callback={callbackOnClose}
        />
      ),
    })
  }

  async function callbackOnDetails(item) {
    try {
      setLoading(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/${item.id}`,
      )

      const email = res.data.email ? ` - ${res.data.email}` : ''
      const phone = res.data.phone ? ` - ${res.data.phone}` : ''

      showModal({
        title: `${res.data.fullName}${email}${phone}`,
        desc: res.data.statusLead,
        body: () => (
          <FormContactForm
            motivationOptions={motivationOptions}
            productOptions={productOptions}
            campaignOptions={campaignOptions}
            observationOptions={observationOptions}
            agentOptions={agentOptions}
            leadFormOptions={leadFormOptions}
            leadStatusOptions={leadStatusOptions}
            callback={callbackOnClose}
            data={{ details: res.data }}
          />
        ),
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function callbackOnClose() {
    await getData()

    hideModal()
  }

  async function callbackProposal(item) {
    try {
      hideModal()

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/proposalcreated/${item.id}`,
      )

      await getData()
    } catch (error) {
      console.log(error)
    }
  }

  async function callbackOnProposal(item) {
    try {
      const customer = {
        id: item.id,
        name: item.customerFullName,
      }

      showModal({
        title: 'Criar proposta',
        body: () => (
          <ProposalCreate
            fromFluxoDeAtendimento={true}
            customer={customer}
            callback={() => callbackProposal(item)}
          />
        ),
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function callbackOnTraveler(item) {
    try {
      setLoading(true)

      if (
        window.confirm(`Deseja criar o viajante: ${item.customerFullName}?`)
      ) {
        const formData = new FormData()

        formData.append('id', item.customerId)

        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/traveler/customer`,
          formData,
        )

        await axiosApis.put(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/travelercreated/${item.id}`,
        )

        setLoading(true)

        toast.success('Viajante criado com sucesso!')

        await getData()
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)

      if (err?.response?.data) {
        toast.info(err.response.data)
      } else {
        toast.info(
          'Não foi possível criar o viajante, por favor tente novamente mais tarde.',
        )
      }
    }
  }

  async function callbackOnTravel(item) {
    const traveler = {
      label: item?.customerFullName,
      value: item?.travelerId,
    }

    showModal({
      title: 'Criar viagem',
      body: () => (
        <Create
          showPageTitle={false}
          showPanelHeader={false}
          fromServiceFlow={true}
          traveler={traveler}
        />
      ),
    })
  }

  async function callbackOnDelete(item) {
    try {
      if (window.confirm('Deseja arquivar o contato?')) {
        await axiosApis.delete(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/card/${item.id}`,
        )

        toast.success('Contato arquivado com sucesso!')
        await getData()
      }
    } catch (error) {
      toast.info('Ocorreu um erro inesperado, por favor tente mais tarde')
    }
  }

  const onValidate = data => {
    if (data.dateEnd) {
      if (data.dateStart) {
        return true
      } else {
        toast.info('Preencha a data Inicial')
        return false
      }
    }

    if (data.dateStart) {
      if (data.dateEnd) {
        return true
      } else {
        toast.info('Preencha a data final')
        return false
      }
    }

    return true
  }

  async function callbackOnAddList() {
    showModal({
      title: 'Criar nova lista',
      body: () => <AddListComponent />,
      width: '40%',
      callback: getData,
    })
  }

  async function onEditList(e) {
    showModal({
      title: 'Editar lista',
      body: () => <EditListComponent data={e} />,
      width: '40%',
      callback: getData,
    })
  }

  async function onMoveList(code, count) {
    try {
      setLoading(true)

      const formData = new FormData()

      formData.append('code', code)
      formData.append('count', count)

      await axiosApis.put(
        `${process.env.REACT_APP_ENDPOINT_APIS}/agencycustomerkanban/move`,
        formData,
      )

      await getData()

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  const highlightColor = card => {
    const normal = '#198754'
    const attention = '#fd7e14'
    const warning = '#dc3545'
    const neutral = '#9E9E9E'

    const comparerDate = card.dateNextAction
      ? getMoment(card.dateNextAction)
      : getMoment(card.updatedAt)

    if (card.dateNextAction == null) {
      return neutral
    }

    if (
      getDateFormated(comparerDate, FORMAT_DATE) ===
      getDateFormated(NOW, FORMAT_DATE)
    ) {
      return attention
    }

    if (comparerDate > NOW) {
      return normal
    }

    return warning
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 className='page-header'>
          Fluxo de atendimentos
          <IconButton
            id={1}
            type={'info'}
            iconOverride={'fa fa-info-circle'}
            tooltipOverride={
              'Tela onde você gerencia e atualiza o status do seu cliente. Acompanha, atualiza e agenda a próxima ação a realizar.'
            }
            style={{ fontSize: '14px' }}
          />
        </h1>

        <IconButton
          id={2}
          type={'filter'}
          iconOverride={'fa fa-filter'}
          tooltipOverride={'Filtros'}
          style={{ fontSize: '12px' }}
          applyColorTheme={true}
          onClick={() => setShowFilter(oldState => !oldState)}
        />
      </div>

      {/* <Dashboard data={dashboards} /> */}

      {showFilter && (
        <Panel>
          <PanelHeader noButton title={'Filtros'} />

          <PanelBody>
            <DefaultForm
              callbackSubmit={e => {
                setParams(e)
                getData(e)
              }}
              callbackReset={() => {
                resetGlobalFilters()
                setParams(undefined)
              }}
              submitLabel={'Pesquisar'}
              defaultValue={pageFilters}
              onValidate={onValidate}
              inputs={[
                auth.user.role == 'admin'
                  ? {
                      type: 'select',
                      name: 'agent',
                      label: 'Consultor',
                      placeholder: 'Selecione...',
                      options: agentOptions,
                      isClearable: true,
                      sm: 12,
                      lg: 3,
                    }
                  : { sm: 12 },
                {
                  type: 'customerSelect',
                  name: 'customer',
                  label: 'Cliente',
                  placeholder: 'Selecione...',
                  isClearable: true,
                  sm: 12,
                  lg: 3,
                },
                {
                  type: 'date',
                  name: 'dateStart',
                  label: 'Data Inicial',
                  placeholder: '',
                  sm: 12,
                  lg: 2,
                },
                {
                  type: 'date',
                  name: 'dateEnd',
                  label: 'Data Final',
                  placeholder: '',
                  sm: 12,
                  lg: 2,
                },
                {
                  type: 'select',
                  name: 'campaign',
                  label: 'Campanha',
                  options: campaignOptions,
                  placeholder: 'Selecione...',
                  isClearable: true,
                  sm: 12,
                  lg: 2,
                },
                {
                  type: 'check',
                  name: 'prospection',
                  label: 'Lead',
                  sm: 12,
                  lg: 2,
                },
              ]}
            />
          </PanelBody>
        </Panel>
      )}

      <Panel>
        {/* <PanelHeader  noButton title='Fluxo de atendimentos' /> */}

        {/* <PanelBody> */}
        <NewKanban
          data={data}
          loading={loading}
          callbackOnMove={callbackOnMove}
          callbackOnAdd={callbackOnAdd}
          callbackOnAddList={callbackOnAddList}
          callbackOnDetails={callbackOnDetails}
          callbackOnProposal={callbackOnProposal}
          callbackOnTraveler={callbackOnTraveler}
          callbackOnTravel={callbackOnTravel}
          callbackOnDelete={callbackOnDelete}
          onEditList={onEditList}
          onMoveList={onMoveList}
          highlightColor={highlightColor}
        />
        {/* </PanelBody> */}
      </Panel>
    </div>
  )
}
