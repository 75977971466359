import { useState, useEffect, useContext } from 'react'
import context from '~/context/createContext'
// import { toast } from 'react-toastify'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from '~/styles/container'
import Table from './Table'
import { useLocation } from 'react-router-dom'
import IconButton from '~/components/IconButton'

export default function ReportAniversariantes() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [agents, setAgents] = useState([])
  const [pageFilters, setPageFilters] = useState({})
  const location = useLocation()

  const arrayOfList = []

  for (let i = 0; i < 32; i++) {
    arrayOfList.push({ label: `${i == 0 ? 'Selecione...' : i}`, value: i })
  }

  const { globalFilters, resetGlobalFilters } = useContext(context)

  useEffect(() => {
    if (location.state?.preset) {
      setPageFilters({
        dateStart: globalFilters.dateStart,
        dateEnd: globalFilters.dateEnd,
      })
    } else {
      resetGlobalFilters()
      setPageFilters({
        dateStart: undefined,
        dateEnd: undefined,
      })
    }
  }, [globalFilters])

  useEffect(() => {
    onSubmit()
  }, [])

  async function onSubmit(data) {
    setLoading(true)

    const response = await axiosApis.get(`/quotation/report/birthday`, {
      params: {
        ...data,
        month: data?.month?.value,
        day: data?.day?.value,
      },
    })

    // setAgentsOriginal(response.data)
    setAgents(response.data)
    setLoading(false)
  }

  // const onValidate = data => {
  //   if (data.dateEnd) {
  //     if (data.dateStart) {
  //       return true
  //     } else {
  //       toast.info('Preencha a data Inical')
  //       return false
  //     }
  //   }

  //   if (data.dateStart) {
  //     if (data.dateEnd) {
  //       return true
  //     } else {
  //       toast.info('Preencha a data final')
  //       return false
  //     }
  //   }

  //   return true
  // }

  return (
    <Container>
      <h1 className='page-header'>
        Aniversariantes
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Lista de aniversariantes do mês, onde você pode mandar uma mensagem padronizada para o WhatsApp.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            // onValidate={onValidate}
            callbackReset={() => resetGlobalFilters()}
            defaultValue={pageFilters}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'text',
                name: 'name_viajante',
                label: 'Nome do Viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'text',
                name: 'email',
                label: 'E-mail',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 3,
              },
              {
                type: 'select',
                name: 'day',
                label: 'Dia',
                placeholder: 'Selecione...',
                options: arrayOfList,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 1,
              },
              {
                type: 'select',
                name: 'month',
                label: 'Mês',
                placeholder: 'Selecione...',
                options: [
                  { label: 'Selecione...', value: 0 },
                  { label: 'Janeiro', value: 1 },
                  { label: 'Fevereiro', value: 2 },
                  { label: 'Março', value: 3 },
                  { label: 'Abril', value: 4 },
                  { label: 'Maio', value: 5 },
                  { label: 'Junho', value: 6 },
                  { label: 'Julho', value: 7 },
                  { label: 'Agosto', value: 8 },
                  { label: 'Setembro', value: 9 },
                  { label: 'Outubro', value: 10 },
                  { label: 'Novembro', value: 11 },
                  { label: 'Dezembro', value: 12 },
                ],
                sm: 12,
                md: 6,
                lg: 4,
                xl: 1,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader noButton title={'Resultado da pesquisa'}></PanelHeader>

        <PanelBody>
          <Table data={agents} loading={loading} />
        </PanelBody>
      </Panel>
    </Container>
  )
}
