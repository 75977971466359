export const SET_SERVICES = 'SET_SERVICES'
export const APPLY_SERVICE_CHANGES = 'APPLY_SERVICE_CHANGES'
export const CLEAR_SERVICE_STATE = 'CLEAR_SERVICE_STATE'

export const setServices = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_SERVICES,
  })
}

export const applyServiceChanges = (data, dispatch) => {
  dispatch({
    data: data,
    type: APPLY_SERVICE_CHANGES,
  })
}

export const clearServiceState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_SERVICE_STATE,
  })
}
