export const SET_CUSTOMER_STATE = 'SET_CUSTOMER_STATE'
export const CLEAR_CUSTOMER_STATE = 'CLEAR_CUSTOMER_STATE'

export const setCustomerState = (data, dispatch) => {
  dispatch({
    data: data,
    type: SET_CUSTOMER_STATE,
  })
}

export const clearCustomerState = dispatch => {
  dispatch({
    data: null,
    type: CLEAR_CUSTOMER_STATE,
  })
}
