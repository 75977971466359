import { DropdownToggle } from 'reactstrap'
import styled from 'styled-components'

export const DropdownContainer = styled.div`
  padding: 0px !important;
  float: right;

  .dropdown.show button {
    background: none;
    color: black;
    box-shadow: none !important;
  }
  .dropdown button {
    box-shadow: none !important;
    background: none;
    color: #939393;
  }
`
export const DropdownButton = styled(DropdownToggle)`
  background: none;
  border: 0;
  color: #939393;
  padding: 0 2px;

  ::after {
    margin-left: 0;
  }

  :hover {
    background: none;
    color: black;
  }
`
