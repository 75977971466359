import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LoadingContainer from '../LoadingContainer'
import {
  createZApiInstance,
  getInstanceQRCode,
  getInstanceStatus,
  saveIdInstance,
} from '~/services/chat'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ZChatQRCode({ user, handleShow }) {
  const auth = useSelector(state => state.auth)
  const [qRCode, setQRCode] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [times, setTimes] = useState(0)

  const intervalStatus = useRef()
  const intervalQRCode = useRef()

  useEffect(() => {
    createInstance(auth.tenant)
  }, [])

  useEffect(() => {
    intervalStatus.current = setInterval(() => checkStatus(auth.tenant), 5000)

    intervalQRCode.current = setInterval(
      () => generateQRCode(auth.tenant),
      12000,
    )

    return () => {
      clearInterval(intervalStatus.current)
      clearInterval(intervalQRCode.current)
    }
  }, [auth])

  useEffect(() => {
    if (times >= 4) {
      clearInterval(intervalStatus.current)
      clearInterval(intervalQRCode.current)
    }
  }, [times])

  async function checkStatus(id) {
    try {
      if (id) {
        const status = await getInstanceStatus(id)

        if (status && status.connected === true) {
          await onConnected()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function onConnected() {
    toast.success('Você já está conectado!')
    handleShow()
    navigate('/chat')

    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  async function createInstance(id) {
    try {
      if (!id) {
        const response = await createZApiInstance(user.name, user.agencyName)

        saveIdInstance(response.id, dispatch)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function generateQRCode(id) {
    try {
      if (id) {
        const currentInstanceData = await getInstanceQRCode(id)

        setQRCode(currentInstanceData.value)

        setTimes(t => t + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function resetIntervals() {
    setTimes(0)

    intervalStatus.current = setInterval(() => checkStatus(auth.tenant), 5000)

    intervalQRCode.current = setInterval(
      () => generateQRCode(auth.tenant),
      12000,
    )
  }

  return (
    <Container data-cy='qr-code'>
      <b style={{ margin: '2px 4px', fontWeight: '600' }}>
        WhatsApp da Agência
      </b>

      <Content>
        {times < 4 && (
          <>
            {qRCode && <img src={qRCode} />}

            {!qRCode && (
              <LoadingContainer size='sm' height='200px' color='#ccc' />
            )}
          </>
        )}

        {times >= 4 && (
          <Button onClick={resetIntervals} size='md'>
            Recarregar <i className='fas fa-redo'></i>
          </Button>
        )}

        <b>Escaneie o QRCode para iniciar uma sessão!</b>

        <ul>
          <li>
            Abra o <b>WhatsApp</b> e acesse o <b>Menu</b>.
          </li>

          <li>
            Vá em <b>Dispositivos Conectados</b> e clique em{' '}
            <b>Conectar um Dispositivo</b>.
          </li>

          <li>
            Escaneie o <b>QRCode acima</b> e pronto!
          </li>
        </ul>
      </Content>
    </Container>
  )
}

const Container = styled.div``

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
