import PageTitle from '~/components/PageTitle'
import { B, Capsule, Content } from './style'
import IconButton from '~/components/IconButton'

export default function HeaderInPage({
  title,
  showFilters,
  showIconFilter = false,
  consultant,
  handleProfile,
}) {
  return (
    <Capsule>
      <Content>
        <i className='fas fa-angle-double-right'></i>
        <PageTitle title={title} head='h6' noButton noMargin />
      </Content>
      <div>
        {showIconFilter && consultant && (
          <span>
            <B handleProfile={handleProfile}>Consultor:</B> {consultant}
          </span>
        )}
        {showIconFilter ? (
          <IconButton
            type={'details'}
            onClick={showFilters}
            applyColorTheme={true}
            iconOverride='fas fa-filter'
          />
        ) : (
          <span></span>
        )}
      </div>
    </Capsule>
  )
}
