import { axiosApis } from '../api'
import axios from 'axios'
import { setTenantKey } from '~/store/root/slices/auth'
import {
  AudioMessage,
  ChatFormDataStrategy,
  DocumentFile,
  ImageFile,
  TextMessage,
  VideoFile,
} from '~/models/ChatFormDataStrategy'
import { getPhoneFormated } from '~/functions/Phone'
import {
  getAlternativePhone,
  isBrasilianPhone,
} from '~/pages/CustomerServiceChat/functions'
const BASE_URL = `${process.env.REACT_APP_CHATCENTER_API_URL}`

export const sendDocument = async (
  formValue,
  to,
  from,
  key,
  messageId,
  referencedMessageContent,
  referencedMessageMedia,
  referencedMessageOwner,
  caption,
) => {
  const data = {
    formValue,
    to,
    from,
    key,
    messageId,
    referencedMessageContent,
    referencedMessageMedia,
    referencedMessageOwner,
    caption,
  }
  const documentFile = new DocumentFile()
  const form = new ChatFormDataStrategy(documentFile)
  form.send(data)
}

export const sendLocation = async formValue => {
  // TODO
  try {
    const formData = new FormData()

    formData.append('Type', 'location')
    formData.append(
      'Location.Address',
      'Av. Ministro José Américo, 326 - Cambeba',
    )
    formData.append('Location.Latitude', '-3.8114968685618047')
    formData.append('Location.Longitude', '-38.49625093176765')
    formData.append('Location.Name', 'AjaxTI')
    formData.append(
      'Location.Url',
      'https://www.google.com/maps/place/Av.+Ministro+Jos%C3%A9+Am%C3%A9rico,+326+-+Cambeba,+Fortaleza+-+CE,+60824-245/@-3.8117591,-38.4988902,17z/data=!3m1!4b1!4m6!3m5!1s0x7c74f91c506e38f:0x61f165682a5e5844!8m2!3d-3.8117645!4d-38.4963153!16s%2Fg%2F11tj1qtflw?hl=pt&entry=ttu',
    )

    await axiosApis.post(`${BASE_URL}/Message`, formData)
  } catch (e) {
    console.log(e)
  }
}

export const sendImageWithCaption = async formValue => {
  // TODO
  try {
    const formData = new FormData()

    formData.append('Type', 'image')
    formData.append('Image.File', formValue)
    formData.append('Image.Caption', 'TESTANDO')

    await axiosApis.post(`${BASE_URL}/Message`, formData)
  } catch (e) {
    console.log(e)
  }
}

export const sendVideoWithCaption = async formValue => {
  //TODO
  try {
    const formData = new FormData()

    formData.append('Type', 'video')
    formData.append('Video.File', formValue)
    formData.append('Video.Caption', 'MULTANDO')

    await axios.post(`${BASE_URL}/message`, formData)
  } catch (e) {
    console.log(e)
  }
}

export const sendTextMessage = async (
  formValue,
  to,
  from,
  key,
  messageId,
  referencedMessageContent,
  referencedMessageMedia,
  referencedMessageOwner,
) => {
  const data = {
    formValue,
    to,
    from,
    key,
    messageId,
    referencedMessageContent,
    referencedMessageMedia,
    referencedMessageOwner,
  }
  const textMessage = new TextMessage()
  const form = new ChatFormDataStrategy(textMessage)
  form.send(data)
}

export const sendAudioMessage = async (
  audio,
  to,
  from,
  key,
  messageId,
  referencedMessageContent,
  referencedMessageMedia,
  referencedMessageOwner,
) => {
  const data = {
    audio,
    to,
    from,
    key,
    messageId,
    referencedMessageContent,
    referencedMessageMedia,
    referencedMessageOwner,
  }
  const audioMessage = new AudioMessage()
  const form = new ChatFormDataStrategy(audioMessage)
  form.send(data)
}

export const sendImage = async (
  image,
  to,
  from,
  key,
  messageId,
  referencedMessageContent,
  referencedMessageMedia,
  referencedMessageOwner,
  caption,
) => {
  const data = {
    image,
    to,
    from,
    key,
    messageId,
    referencedMessageContent,
    referencedMessageMedia,
    referencedMessageOwner,
    caption,
  }
  const imageFile = new ImageFile()
  const form = new ChatFormDataStrategy(imageFile)
  form.send(data)
}

//TODO
export const sendContacts = async formValue => {
  try {
    const formData = new FormData()

    formData.append('Type', 'contacts')
    formData.append('Contacts[0].Name.FirstName', 'João Paulo')
    formData.append('Contacts[0].Name.FormattedName', 'João Paulo')
    formData.append('Contacts[0].Phones[0].PhoneNumber', '558599251015')
    formData.append('Contacts[0].Phones[0].WaId', '558599251015')
    formData.append('Contacts[0].Phones[0].Type', 'CELL')

    await axiosApis.post(`${BASE_URL}/Message`, formData)
  } catch (e) {
    console.log(e)
  }
}

export const sendVideo = async (
  media,
  to,
  from,
  key,
  messageId,
  referencedMessageContent,
  referencedMessageMedia,
  referencedMessageOwner,
  caption,
) => {
  const data = {
    media,
    to,
    from,
    key,
    messageId,
    referencedMessageContent,
    referencedMessageMedia,
    referencedMessageOwner,
    caption,
  }
  console.log(media)
  const videoFile = new VideoFile()
  const form = new ChatFormDataStrategy(videoFile)
  form.send(data)
}
//TODO
// export const  = async formValue => {
//   try {
//     const formData = new FormData()

//     formData.append('Type', 'video')
//     formData.append('Video.File', formValue)
//     // formData.append('')

//     await axiosApis.post(`${BASE_URL}/Message`, formData)
//   } catch (e) {
//     console.log(e)
//   }
// }

//TODO
export const sendSticker = async formValue => {
  try {
    const formData = new FormData()

    formData.append('Type', 'sticker')
    formData.append('Sticker.File', formValue)
    formData.append('Sticker.Animated', 'false')

    await axiosApis.post(`${BASE_URL}/Message`, formData)
  } catch (e) {
    console.log(e)
  }
}

//TODO
export const replyMessage = async formValue => {
  try {
    const formData = new FormData()

    formData.append('Type', 'text')
    formData.append('Text.Body', 'RESPOSTA')
    formData.append(
      'InReplyTo',
      'wamid.HBgMNTU4NTk5MjUxMDE1FQIAERgSOUQ2RTVFOUZDRTdFQjk3RjAxAA==',
    )

    await axiosApis.post(`${BASE_URL}/Message`, formData)
  } catch (e) {
    console.log(e)
  }
}

export const createZApiInstance = async (name, agencyName) => {
  try {
    const response = await axiosApis.post(`${BASE_URL}/Instance/Create `, {
      client: `${agencyName} - ${name}`,
      businessInstance: false,
      autoSubscribe: false,
      validationToken: process.env.REACT_APP_TOKEN_ACCESS_API_CHATCENTER,
    })
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const getInstanceQRCode = async instanceId => {
  try {
    const response = await axiosApis.get(
      `${BASE_URL}/instance/${instanceId}/qrcode`,
    )
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const getInstanceStatus = async instanceId => {
  try {
    const response = await axiosApis.get(
      `${BASE_URL}/instance/${instanceId}/status`,
    )
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const getAllContacts = async instanceId => {
  try {
    const response = await axiosApis.get(
      `${BASE_URL}/contacts/GetContacts/${instanceId}`,
    )
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const saveIdInstance = async (id, dispatch) => {
  await axiosApis.put(
    `${process.env.REACT_APP_ENDPOINT_APIS}/agency/Instance`,
    {
      WppInstanceId: id,
    },
  )
  dispatch(
    setTenantKey({
      tenant: id,
    }),
  )
}

export const getClientsDataForContacts = async phoneList => {
  // const config = {
  //   params: {
  //     phones: phoneList,
  //   },
  // }
  // console.log(phoneList)

  return await axiosApis.post(
    `${process.env.REACT_APP_ENDPOINT_APIS}/customer/get-rating-by-phone`,
    phoneList,
  )
}

export const getClientsDataForContactsAlternative = async phoneList => {
  const newPhoneList = phoneList
    .filter(x => isBrasilianPhone(x))
    .map(x => getAlternativePhone(x))

  // const config = {
  //   params: {
  //     phones: newPhoneList,
  //   },
  // }

  // console.log(newPhoneList)

  return await axiosApis.post(
    `${process.env.REACT_APP_ENDPOINT_APIS}/customer/get-rating-by-phone`,
    newPhoneList,
  )
}

export const getCustomerInfo = async number => {
  return await axiosApis.get(
    `${process.env.REACT_APP_ENDPOINT_APIS}/customer`,
    {
      params: {
        phone: number.includes('-group') ? number : getPhoneFormated(number),
      },
    },
  )
}

export const getAgentsFromAgency = async () => {
  return await axiosApis.options(`${process.env.REACT_APP_ENDPOINT_APIS}/agent`)
}
