import axios from 'axios'
import { toast } from 'react-toastify'
const BASE_URL = `${process.env.REACT_APP_CHATCENTER_API_URL}`

export class AudioMessage {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.from)
    formData.append('From', data.to)
    formData.append('Type', 'audio')
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Image.File', '')
    formData.append('Document.File', '')
    formData.append('Video.File', '')
    formData.append('Image.Caption', '')
    formData.append('Video.Caption', '')
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Location.Address', '')
    formData.append('Text.Body', '')
    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    formData.append('Audio.Voice', true)
    formData.append('Audio.File', data.audio)
    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar mensagem de áudio')
      console.log(e)
    }
  }
}

export class DocumentFile {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.to)
    formData.append('From', data.from)
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Image.File', '')
    formData.append('Video.File', '')
    formData.append('Image.Caption', '')
    formData.append('Video.Caption', '')
    formData.append('Audio.File', '')
    formData.append('Document.Caption', data.caption ?? '')
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Audio.Voice', '')
    formData.append('Location.Address', '')
    formData.append('Text.Body', '')

    formData.append('Type', 'document')
    formData.append('Document.File', data.formValue)
    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar documento')
      console.log(e)
    }
  }
}

export class TextMessage {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.to)
    formData.append('From', data.from)
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Image.File', '')
    formData.append('Document.File', '')
    formData.append('Video.File', '')
    formData.append('Image.Caption', '')
    formData.append('Video.Caption', '')
    formData.append('Audio.File', '')
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Audio.Voice', '')
    formData.append('Location.Address', '')

    formData.append('Type', 'text')
    formData.append('Text.Body', data.formValue)

    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar mensagem de texto')
      console.log(e)
    }
  }
}

export class ImageFile {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.to)
    formData.append('From', data.from)
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Video.File', '')
    formData.append('Image.Caption', data.caption ?? '')
    formData.append('Video.Caption', '')
    formData.append('Audio.File', '')
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Audio.Voice', '')
    formData.append('Location.Address', '')
    formData.append('Text.Body', '')
    formData.append('Document.File', '')

    formData.append('Type', 'image')
    formData.append('Image.File', data.image)
    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar imagem')
      console.log(e)
    }
  }
}

export class VideoFile {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.to)
    formData.append('From', data.from)
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Video.File', data.media)
    formData.append('Image.Caption', '')
    formData.append('Video.Caption', data.caption ?? '')
    formData.append('Audio.File', '')
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Audio.Voice', '')
    formData.append('Location.Address', '')
    formData.append('Text.Body', '')
    formData.append('Document.File', '')

    formData.append('Type', 'video')
    formData.append('Image.File', '')
    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar imagem')
      console.log(e)
    }
  }
}

export class AudioFile {
  async send(data) {
    let formData = new FormData()

    formData.append('To', data.to)
    formData.append('From', data.from)
    formData.append('InReplyTo', '')
    formData.append('Location.Name', '')
    formData.append('Location.Latitude', '')
    formData.append('Sticker.File', '')
    formData.append('Video.File', '')
    formData.append('Image.Caption', '')
    formData.append('Video.Caption', '')
    formData.append('Audio.File', data.media)
    formData.append('Sticker.Animated', '')
    formData.append('Location.Longitude', '')
    formData.append('Location.Url', '')
    formData.append('Audio.Voice', data.media)
    formData.append('Location.Address', '')
    formData.append('Text.Body', '')
    formData.append('Document.File', '')

    formData.append('Type', 'image')
    formData.append('Image.File', '')
    formData.append('MessageId', data.messageId ?? '')
    formData.append(
      'ReferencedMessageContent',
      data.referencedMessageContent ?? '',
    )
    formData.append('ReferencedMessageMedia', data.referencedMessageMedia ?? '')
    formData.append('ReferencedMessageOwner', data.referencedMessageOwner ?? '')

    try {
      await axios.post(`${BASE_URL}/Message`, formData, {
        headers: {
          'X-Tenant': data.key,
        },
      })
    } catch (e) {
      toast.error('Erro ao enviar imagem')
      console.log(e)
    }
  }
}

export class ChatFormDataStrategy {
  constructor(strategy) {
    this.strategy = strategy
  }

  setStrategy(strategy, key) {
    this.strategy = strategy
    this.key = key
  }

  async send(data) {
    await this.strategy.send(data)
  }
}
