import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import { fileToBase64 } from '~/functions/File'

export const onSubmit = async ({ data, setLoading, navigate }) => {
  if (!data.photo) {
    toast.info('É necessário adicionar uma foto para o produto.')
    return
  }

  const body = {
    ...data,
    photo: await fileToBase64(data.photo),
  }

  const response = await apiRequest(
    'post',
    `product`,
    body,
    setLoading,
    'Produto atualizado com sucesso.',
  )

  if (response.error == false) navigate(-1)
}
