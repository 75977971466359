import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingBackofficeHomeData from '../../dataHooks/useHoldingBackofficeHome'
import HeaderInPage from '../../components/HeaderInPage'

export default function Home({
  branchs,
  filters,
  handleProfile,
  showSalesStats = true,
}) {
  const {
    loading,
    dashboards,
    ammountLast6Months,
    newProposalsLast6Months,
    newLeadsLast6Months,
  } = useHoldingBackofficeHomeData({ filters, showSalesStats })

  return (
    <Container>
      <HeaderInPage title='Home' />

      <Divisor margin='5px' />

      <AnalyticsDashboard data={dashboards} columnSizingPerRow={'33.33%'} />

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Novos leads(últimos 6 meses)'>
            <AnalyticsLineChart data={newLeadsLast6Months} />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Novas propostas(últimos 6 meses)'>
            <AnalyticsLineChart data={newProposalsLast6Months} currency />
          </AnalyticsContainer>
        </Col>

        {showSalesStats && (
          <Col sm={12} xl={12}>
            <AnalyticsContainer title='Faturamento(últimos 6 meses)'>
              <AnalyticsLineChart data={ammountLast6Months} currency />
            </AnalyticsContainer>
          </Col>
        )}
      </Row>

      <Divisor margin='5px' />

      {!handleProfile && (
        <AnalyticsTable
          title='Franqueados'
          data={branchs}
          loading={loading}
          columns={[
            {
              Header: 'Nome',
              accessor: 'name',
              width: 150,
            },
            {
              Header: 'E-mail',
              accessor: 'email',
              width: 300,
            },
            {
              Header: 'Telefone',
              accessor: 'phone',
              width: 150,
            },
            {
              Header: 'CNPJ',
              accessor: 'cpfCnpj',
              width: 200,
            },
          ]}
        />
      )}
    </Container>
  )
}

const Container = styled.div``
