import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: null,
  signed: false,
  loading: false,
  user: null,
  tenant: null,
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    onUserUpdate: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    requestingLogin: () => ({
      ...initialState,
      loading: true,
    }),
    loginSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      signed: true,
      accessToken: payload.accessToken,
      user: payload.user,
      tenant: payload.tenant,
    }),
    loginFailed: () => ({
      ...initialState,
    }),
    signOut: () => ({
      ...initialState,
    }),
    loading: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    setTenantKey: (state, { payload }) => {
      return {
        ...state,
        tenant: payload.tenant,
      }
    },
    setAgencyPhone: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          agencyPhone: payload.agencyPhone,
          agencyPhoneDDI: payload.agencyPhoneDDI,
        },
      }
    },
  },
})

export const {
  onUserUpdate,
  requestingLogin,
  loginSuccess,
  loginFailed,
  signOut,
  loading,
  setTenantKey,
  setAgencyPhone,
  changeStatus,
} = slice.actions

export default slice.reducer
