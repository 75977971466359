import {
  APPLY_SERVICE_CHANGES,
  CLEAR_SERVICE_STATE,
  SET_SERVICES,
} from '../actions/serviceAction'

const initialState = {
  services: [],
}

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICES: {
      return {
        services: action.data ?? [],
      }
    }
    case APPLY_SERVICE_CHANGES: {
      const servicesToDelete = action.data.filter(x => x.deleted)

      const servicesToAdd = action.data.filter(x => !x.deleted)

      const newServices = []

      state.services.forEach(element => {
        let finded = false

        if (servicesToAdd.find(x => x.doc.phone === element.phone)) {
          finded = true
        }

        if (servicesToDelete.find(x => x.id === element._id)) {
          finded = true
        }

        if (!finded) {
          newServices.push(element)
        }
      })

      return {
        services: [...newServices, ...servicesToAdd.map(x => x.doc)],
      }
    }
    case CLEAR_SERVICE_STATE:
      return {
        ...initialState,
      }
    default: {
      return state
    }
  }
}

export default serviceReducer
