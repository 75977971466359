import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import IconButton from '~/components/IconButton'
import { LinkEdit } from '~/components/Link/styles'
import ReactTable from '~/components/Table'
import { GetAxios } from '~/services/api'

export default function Table({ data, loading, getAgents, isMaster }) {
  const { axiosApis } = GetAxios()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    let cancel = false

    async function loadSource() {
      if (cancel) return

      try {
        const { data } = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/agency/roles`,
        )

        return setRoles(data)
      } catch (error) {
        console.log(error)
      }
    }

    loadSource()

    return () => {
      cancel = true
    }
  }, [])

  // const onRemove = async id => {
  //   if (window.confirm('Tem certeza que deseja excluir esse consultor?')) {
  //     const result = await apiRequest('delete', `agent/${id}`, {})

  //     if (result.error) {
  //       toast.info(result?.erroMessage)
  //       return
  //     }

  //     getAgents()

  //     !result.error && toast.success(result?.data?.message)
  //   }
  // }

  const inputs = [
    {
      Header: 'Nome Completo',
      accessor: 'fullName',
      width: 175,
    },
    {
      Header: 'Perfil',
      accessor: 'role',
      Cell: props => {
        var res = ''

        roles.map(item => {
          if (item.value === props.row.original.role) {
            res = item.label
          }
        })

        return res
      },
      width: 150,
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      width: 200,
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      width: 120,
    },
    {
      Header: 'Agencia',
      accessor: 'agencyName',
      width: 120,
    },
    {
      Header: 'Ultimo Acesso',
      accessor: 'activities.lastView',
      width: 120,
    },
    {
      Header: 'Acessos',
      accessor: 'activities.numberOfViews',
      width: 100,
    },
    {
      Header: 'Senha Padrão',
      accessor: 'declaredPassword',
      width: 120,
    },
    {
      Header: 'Status',
      accessor: 'textDisabled',
      width: 120,
    },
    {
      Header: '',
      accessor: 'details',
      Cell: props => {
        return (
          <LinkEdit to={'details'} state={props.row.original}>
            <i className={'fas fa-edit'} />
          </LinkEdit>
        )
      },
      width: 20,
    },
    // {
    //   Header: '',
    //   accessor: 'remove',
    //   Cell: props => {
    //     return (
    //       <IconButton
    //         id={props.row.original.id}
    //         type={'remove'}
    //         onClick={() => {
    //           onRemove(props.row.original.id)
    //         }}
    //       />
    //     )
    //   },
    //   width: 20,
    // },
  ]

  return (
    <ReactTable
      data={data}
      columns={isMaster ? inputs : inputs.filter(x => x.Header != 'Agencia')}
      tableProps={{
        defaultPageSize: 10,
        hasFooter: false,
        hasPagination: true,
        loading: loading,
        resizable: true,
      }}
      tableStyle={{
        fontSize: '13px',
        cellPadding: '6px 4px',
      }}
      tableLabels={{
        loadingText: 'Carregando...',
        noDataText: 'Sem registros',
        nextButtonText: 'Próximo',
        previousButtonText: 'Anterior',
        pageOfText: 'de',
        pageSizeSelectText: 'registros',
        pageText: 'Página',
      }}
    />
  )
}
