import { useEffect, useState } from 'react'
import { copyTravelLink, getData, redirectToTravelLink } from './functions'
import { DivContainer } from './styles'
import { LongLink, ShortLink } from './components'

export default function ModalLinks() {
  const [longLink, setLongLink] = useState('   Carregando...')
  const [shortLink, setShortLink] = useState(null)
  const [leadFormOptions, setLeadFormOptions] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData({
      setLongLink,
      setLeadFormOptions,
      setCampaignOptions,
      setProductOptions,
    })
  }, [])

  return (
    <>
      <DivContainer>
        <LongLink
          copyTravelLink={copyTravelLink}
          redirectToTravelLink={redirectToTravelLink}
          longLink={longLink}
        />
      </DivContainer>

      <br />

      <DivContainer>
        <ShortLink
          copyTravelLink={copyTravelLink}
          redirectToTravelLink={redirectToTravelLink}
          leadFormOptions={leadFormOptions}
          campaignOptions={campaignOptions}
          productOptions={productOptions}
          shortLink={shortLink}
          setShortLink={setShortLink}
        />
      </DivContainer>
    </>
  )
}
