import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'

export default function BackofficeBranchsCreate() {
  const navigate = useNavigate()
  const location = useLocation()
  const { axiosApis } = GetAxios()
  const [agencyOptions, setAgencyOptions] = useState([])

  useEffect(async () => {
    await getSource()

    return () => {
      setAgencyOptions([])
    }
  }, [])

  async function getSource() {
    try {
      const res = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/backofficebranch/${location.state.agency.id}`,
      )

      setAgencyOptions(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function onValidate(data) {
    if (!data.branch) {
      toast.info('Preencha o campo Agência.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      const body = {
        id: data.branch.value,
        code: data.code,
        headId: location.state.agency.id,
      }

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/backofficebranch`,
        body,
      )

      toast.success('Filial adicionada com sucesso!')

      navigate(-1)
    } catch (error) {
      console.log(error)

      toast.info('Não foi possível adicionar a filial, tente mais tarde.')
    }
  }

  return (
    <Container>
      <PageTitle title={`Nova filial para ${location.state.agency.name}`} />

      <Panel>
        <PanelHeader title={'Filtros'} noButton />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {}}
            onValidate={onValidate}
            inputs={[
              {
                type: 'select',
                name: 'branch',
                label: 'Agência',
                options: agencyOptions,
                required: true,
              },
              {
                type: 'text',
                name: 'code',
                label: 'Código filial',
                required: true,
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}

const Container = styled.div``
