import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import AnalyticsTable from '~/components/Analytics/Table'
import Divisor from '~/components/Divisor'
import useHoldingBackofficeCustomersData from '../../dataHooks/useHoldingBackofficeCustomers'
import HeaderInPage from '../../components/HeaderInPage'
import ModalDefault from '~/components/ModalDefault'
import { useEffect, useState } from 'react'
import useModal from '~/hooks/useModal'
import DefaultForm from '~/components/DefaultForm'

export default function HoldingBackofficeCustomers({
  filters,
  handleProfile,
  consultantOptions,
}) {
  const [handleShow, setHandleShow] = useState(false)
  const [customersFilter, setCustomersFilter] = useState(filters)

  useEffect(
    () =>
      setCustomersFilter(oldState => ({
        ...filters,
        consultant: oldState.consultant,
      })),
    [filters],
  )

  const {
    loading,
    dashboards,
    dashboardsFluxoDeAtendimento,
    tableData,
    canceledProspectionsChart,
    leadContactFormChart,
    lossRateDetails,
  } = useHoldingBackofficeCustomersData({
    customersFilter,
    handleProfile,
  })

  const { showModal, hideModal } = useModal()

  const inputsRender = () => {
    const inputs = [
      {
        Header: 'Destino',
        accessor: 'state',
        width: 300,
      },
      {
        Header: 'Quantidade',
        accessor: 'count',
        width: 300,
      },
    ]

    return inputs
  }

  const cardSize = arr => {
    let size = '100%'

    switch (arr) {
      case 1:
        size = '100%'
        break
      case 2:
        size = '50%'
        break
      case 3:
        size = '33.33%'
        break
      case 4:
        size = '25%'
        break
      default:
        size = '20%'
        break
    }

    return size
  }

  const handleCustomerFilter = data => {
    setCustomersFilter(oldState => ({
      ...oldState,
      consultant: data?.consultant,
    }))

    hideModal()
  }

  const showFilters = () => {
    showModal({
      title: 'Filtros',
      body: () => (
        <div style={{ width: '100%' }}>
          <DefaultForm
            callbackSubmit={handleCustomerFilter}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'consultant',
                label: 'Consultor',
                placeholder: 'Selecione...',
                options: consultantOptions,
                isClearable: true,
                sm: 12,
                lg: 12,
              },
            ]}
          />
        </div>
      ),
    })
  }

  // const dynamicDashboard = () => {
  //   for (let key in lossRateDetails) {
  //     if (lossRateDetails[key].length >= 1) {
  //       console.log(Object.values(lossRateDetails[key]))
  //       Object.values(lossRateDetails[key]).map((lossRate, i) => (
  //         <div key={i}>
  //           <HeaderInPage title={key} />
  //           <AnalyticsDashboard
  //             data={lossRateDetails[key]}
  //             columnSizingPerRow={cardSize(lossRateDetails[key].length)}
  //           />
  //           <Divisor margin='5px' />
  //         </div>
  //       ))
  //     }
  //   }
  // }

  return (
    <Container>
      <HeaderInPage title='Clientes' />

      <Divisor margin='5px' />

      <AnalyticsDashboard data={dashboards} />

      <Divisor margin='5px' />

      <HeaderInPage
        title='Fluxo de atendimento'
        showFilters={showFilters}
        showIconFilter={true}
        consultant={customersFilter?.consultant?.label}
        handleProfile={handleProfile}
      />

      <Divisor margin='5px' />

      {handleShow && (
        <ModalDefault
          title={'Detalhamento da taxa de perda associados à:'}
          content={
            <>
              {lossRateDetails?.cards?.length >= 1 && (
                <>
                  <HeaderInPage title='Cards' />
                  <AnalyticsDashboard
                    data={lossRateDetails?.cards}
                    columnSizingPerRow={cardSize(
                      lossRateDetails?.cards?.length,
                    )}
                  />
                  <Divisor margin='5px' />
                </>
              )}

              {lossRateDetails?.motivations?.length >= 1 && (
                <>
                  <HeaderInPage title='Motivos' />
                  <AnalyticsDashboard
                    data={lossRateDetails?.motivations}
                    columnSizingPerRow={cardSize(
                      lossRateDetails?.motivations?.length,
                    )}
                  />
                  <Divisor margin='5px' />
                </>
              )}

              {lossRateDetails?.products?.length >= 1 && (
                <>
                  <HeaderInPage title='Produtos' />
                  <AnalyticsDashboard
                    data={lossRateDetails?.products}
                    columnSizingPerRow={cardSize(
                      lossRateDetails?.products?.length,
                    )}
                  />
                  <Divisor margin='5px' />
                </>
              )}

              {lossRateDetails?.campaigns?.length >= 1 && (
                <>
                  <HeaderInPage title='Campanhas' />
                  <AnalyticsDashboard
                    data={lossRateDetails?.campaigns}
                    columnSizingPerRow={cardSize(
                      lossRateDetails?.campaigns?.length,
                    )}
                  />
                  <Divisor margin='5px' />
                </>
              )}

              {lossRateDetails?.contactForms?.length >= 1 && (
                <>
                  <HeaderInPage title='Formas de contato' />
                  <AnalyticsDashboard
                    data={lossRateDetails?.contactForms}
                    columnSizingPerRow={cardSize(
                      lossRateDetails?.contactForms?.length,
                    )}
                  />
                </>
              )}
            </>
          }
          handleShow={() => setHandleShow(oldState => !oldState)}
        />
      )}

      <AnalyticsDashboard
        data={dashboardsFluxoDeAtendimento}
        columnSizingPerRow={'33.33%'}
        handleShow={() => setHandleShow(oldState => !oldState)}
        loading={loading}
      />

      <Divisor margin='5px' />

      <HeaderInPage title='Leads' />

      <Divisor margin='5px' />

      <Row>
        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Leads perdidos'>
            <AnalyticsLineChart data={canceledProspectionsChart} />
          </AnalyticsContainer>
        </Col>

        <Col sm={12} xl={6}>
          <AnalyticsContainer title='Formas de contato'>
            <AnalyticsLineChart data={leadContactFormChart} />
          </AnalyticsContainer>
        </Col>
      </Row>

      <Divisor margin='5px' />

      <AnalyticsTable
        title='Por onde meus clientes passaram'
        data={tableData}
        loading={loading}
        columns={inputsRender()}
      />
    </Container>
  )
}

const Container = styled.div``
