import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingContainer from '~/components/LoadingContainer'
import { getOptionsDDI, getSource } from './functions'
import { Container, Div } from './style'
import { Body, Footer, Header } from './components'

export default function ShortForm() {
  const { token } = useParams()
  const [loading, setLaoding] = useState(false)
  const [ddi, setDdi] = useState([])
  const [data, setData] = useState({})
  const [widthCurrent, setWidthCurrent] = useState(window.innerWidth)

  document.title = 'Plataforma CRM especializada em agências de viagens'

  useEffect(() => {
    getSource({ token, setLaoding, setData })
    getOptionsDDI({ setDdi })
  }, [])

  window.addEventListener('resize', () => {
    setWidthCurrent(window.innerWidth)
  })

  return (
    <Div>
      {loading && <LoadingContainer />}
      {!loading && (
        <>
          <Header agencyPhoto={data.agencyPhoto} />
          <Container>
            <Body
              data={data}
              widthCurrent={widthCurrent}
              token={token}
              ddi={ddi}
            />
          </Container>
          <Footer agencyPhoto={data.agencyPhoto} />
        </>
      )}
    </Div>
  )
}
